<template>
  <ElFormItem :prop="props.fieldName" :label="label">
    <caption v-if="captionContent">
      {{
        captionContent
      }}
    </caption>
    <section
      class="p-20px <sm:p-12px <sm:rounded-5px @sm:p-14px @sm:rounded-6px @md:p-14px @md:rounded-6px @lg:p-16px @lg:rounded-7px key-type rounded-8px w-full"
    >
      <ElUpload
        ref="uploadRef"
        :auto-upload="false"
        :show-file-list="false"
        class="w-full"
        :class="!!imageDataModel && 'image-selected'"
        :disabled="isDisabled"
        @change="handleAvatarSuccess"
      >
        <div
          class="upload-container cursor-default w-full flex items-center justify-center rounded-8px h-200px <sm:rounded-5px <sm:h-151px @sm:rounded-6px @sm:h-161px @md:h-171px @md:rounded-6px @lg:h-181px @lg:rounded-7px"
        >
          <div
            v-show="!imageDataModel"
            class="p-14px upload-icon text-20px <sm:p-12px <sm:text-18px flex items-center"
          >
            <CommonAsset name="icons-upload" />
          </div>
          <CommonAsset
            v-show="imageDataModel"
            class="w-full h-full"
            :src="imageSrc"
            :alt="`kyc-image-${props.fieldName}`"
          />
        </div>
      </ElUpload>
    </section>
    <Transition>
      <div
        v-if="isLicenceModelShow"
        class="fixed top-0 left-0 w-[100vw] h-[100vh] z-10"
      >
        <div
          class="w-full h-full licence-overlay"
          @click="isLicenceModelShow = false"
        ></div>
        <div
          class="licence-model w-[90%] max-w-400px p-20px m-auto bg-[#262836]"
        >
          <div class="flex justify-end mb-20px">
            <CommonAsset
              name="icons-close"
              @click="isLicenceModelShow = false"
            />
          </div>
          <p class="mb-10px !text-[#FFFFFF]">
            Photo requirements: (need to meet the following requirements,
            otherwise will not be reviewed and approved)
          </p>
          <ul class="list-decimal pl-16px mb-15px !text-[#FFFFFF]">
            <li>Clear and unobstructed face</li>
            <li>ID all information without blockage</li>
          </ul>
          <p class="mb-12px !text-[#FFFFFF]">
            Selfie with ID Card portrait photo samples:
          </p>
          <CommonAsset
            name="images-kyc-handheld-example"
            class="w-[100%] m-auto rounded-[8px]"
          />
        </div>
      </div>
    </Transition>
  </ElFormItem>
</template>
<script lang="ts" setup>
import { ElFormItem } from "element-plus"
import type { UploadProps, UploadInstance } from "element-plus"
import { computed } from "vue"
import { useKycForm, kycFormScopedRef } from "#imports"
import { EnumKycStatus } from "~/types/enum"

const props = defineProps<{
  fieldName: "imgHandheld" | "imgFront" | "imgBack"
  imageData: string
}>()
const { t } = useLang()

const kycManager = useKycForm()
const isLicenceModelShow = ref(false)
const label = computed(() => {
  switch (props.fieldName) {
    case "imgFront":
      return t('kyc.front')
    case "imgBack":
      return t('kyc.back')
    case "imgHandheld":
      return t('kyc.selfie')
    default:
      return ""
  }
})
const captionContent = computed(() => {
  switch (props.fieldName) {
    case "imgFront":
      return t('kyc.faceInfo01')
    case "imgHandheld":
      return t('kyc.faceInfo02')
    default:
      return null
  }
})
const emits = defineEmits(["update:imageData"])

const { formRef, refs } = useKycForm()

const imageDataModel = computed({
  get() {
    return props.imageData
  },
  set(value) {
    emits("update:imageData", value)
  },
})

const imageSrc = computed(() => {
  return typeof imageDataModel.value === "string"
    ? imageDataModel.value
    : imageDataModel.value
    ? URL.createObjectURL(imageDataModel.value)
    : undefined
})

const refKey = computed(() => {
  switch (props.fieldName) {
    case "imgFront":
      return "uploadRefImgFront"
    case "imgBack":
      return "uploadRefImgBack"
    case "imgHandheld":
      return "uploadRefImgHandheld"
    default:
      return ""
  }
})

// @ts-ignore
const uploadRef = refs[refKey.value] as Ref<UploadInstance>

const handleAvatarSuccess: UploadProps["onChange"] = (uploadFile) => {
  // @ts-ignore
  imageDataModel.value = uploadFile.raw as File
  formRef?.value?.validateField(props.fieldName)
}

const isDisabled = computed(() => {
  const isDataHasValue = !!kycFormScopedRef.originalData[props.fieldName]
  const isKycStatusReUploadAllowed = (() => {
    switch (kycManager.kycStatus.value) {
      case EnumKycStatus.AI_APPROVED:
      case EnumKycStatus.AI_REJECTED:
      case EnumKycStatus.AI_SKIPPED:
        return isDataHasValue
      case EnumKycStatus.INITIAL:
        return aiKycSwitchStatusSync.value
      case EnumKycStatus.CSR_REJECTED:
        return false
    }
    return kycManager.isFormDisabled.value
  })()

  return isKycStatusReUploadAllowed
})
</script>
<style lang="scss" scoped>
caption {
  font-size: 14px;
  line-height: 1.5em;
  color: #a5acb8;
  margin-bottom: 1em;
  display: block;
  padding: 0.5em 1em;
  background: #1e202b;
  width: 100%;
  text-align: left;
  border-radius: 0.5em;
}
.key-type {
  background: #1e202b;
  color: #fff;
  :deep(.el-upload) {
    width: 100%;
  }
  .upload-container {
    background: #262836;
    border: 1px dashed #a5acb8;
    .upload-icon {
      background: linear-gradient(
        180deg,
        #1999ee -9.78%,
        #115bca 26.02%,
        #0b3aa8 111.96%
      );
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
    }
  }
}

.preview-status {
  background: linear-gradient(
    180deg,
    #1999ee -9.78%,
    #115bca 26.02%,
    #0b3aa8 111.96%
  );
  border-radius: 0 0 20px 20px;
}

.reminders-list {
  list-style-type: ". ";
}

.el-select-dropdown__item {
  overflow-wrap: break-word;
  white-space: normal;
  height: auto !important;
  max-width: calc(100vw - 91px);
}

.image-selected {
  margin-bottom: -10px;
}
.licence-overlay {
  background-color: rgba(10, 10, 10, 0.4);
}
.licence-model {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;

  * {
    text-align: left;
    line-height: 1.5em;
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<script setup lang="tsx">
const route = useRoute()
const { t } = useI18n()

const __show = shallowRef(false)

const contentMapKey = {
  CompleteKycToGetBonus: "CompleteKycToGetBonus",
}

const dialogType = computed(() => {
  return route.query.dialogType as DialogTypeKey
})

const conditions = {
  [contentMapKey.CompleteKycToGetBonus]: computed(() => {
    return dialogType.value === "kycSetting"
  }),
}

const show = computed({
  get() {
    return (
      __show.value &&
      Object.values(conditions).some((condition) => condition.value)
    )
  },
  set(val) {
    __show.value = val
  },
})

const contentMap = {
  [contentMapKey.CompleteKycToGetBonus]: `${t('kyc.bonus')}`,
}

const content = computed(() => {
  show.value = true
  const target = Object.entries(conditions).filter(([, condition]) => {
    return condition.value
  })?.[0]?.[0]
  return target ? contentMap[target] : ""
})

const display = shallowRef(true)

const close = () => {
  display.value = false
  setTimeout(() => {
    show.value = false
  }, 500)
}

watch(
  dialogType,
  (val) => {
    __show.value = !!val
    display.value = !!val
  },
  { immediate: true }
)
</script>

<template>
  <div v-if="show" @click="close" class="NotificationHeader">
    <div class="Inner" :data-display="display">
      <div class="Content">{{ content }}</div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
/* animation fade in */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.NotificationHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .Inner {
    display: flex;
    align-items: center;
    justify-content: center;
    /* apply animation */
    background-color: var(--v-dialog-bg);
    border-radius: 0 0 1.5em 1.5em;
    animation: fadeIn 0.5s ease-in-out;
    width: auto;
    max-width: 800px;
    background: linear-gradient(
      180deg,
      #1999ee -9.78%,
      #115bca 26.02%,
      #0b3aa8 111.96%
    );

    &[data-display="false"] {
      animation: fadeOut 0.5s ease-in-out;
    }

    @screen <sm {
      max-width: 100%;
      width: 100%;
      padding: 0 1em;
    }

    .Content {
      padding: 0.5em 1em;
      color: rgb(255, 255, 255);
      font-size: 1.2em;
      font-weight: 500;
      text-align: left;
      @screen <sm {
        text-align: left;
        font-size: 0.8em;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: auto;
        text-align: center;
        white-space: pre-wrap;
      }
    }
  }
}
</style>

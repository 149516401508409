<script setup lang="ts">
import { useComponentsContext } from "~/composables/useComponentsContext"
const { context } = useComponentsContext()
const props = withDefaults(
  defineProps<{ isShow?: Boolean; isFixed?: Boolean }>(),
  {
    isShow() {
      return true
    },
    isFixed() {
      return true
    },
  }
)
const className = computed(() =>
  props.isFixed
    ? "components-loading-mask-fixed"
    : "components-loading-mask-non-fixed"
)
</script>
<template>
  <transition name="el-fade-in-linear">
    <div v-show="props.isShow ?? context.isLoadingMaskOpen" :class="className">
      <!-- <CommonAsset name="images-common-loading" alt="loading" /> -->

      <!-- 活动 -->
      <div class="flex items-center">
        <CommonAsset
          name="images-common-loading-christmas-left"
          class="loading-christmas-left w-50px mr-10px"
          alt="loading-left"
        />
        <CommonAsset
          name="images-common-loading"
          class="loading-christmas-right w-0"
          alt="loading-right"
        />
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.components-loading-mask-fixed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 10001;
  background-color: var(--v-components-loading-mask-bg);
}
.components-loading-mask-non-fixed {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--v-components-loading-mask-bg);
}
// 活动
.loading-christmas-left {
  animation: christmasLeftAnimation 1.5s infinite;
}

.loading-christmas-right {
  animation: christmasRightAnimation 1.5s infinite;
}

@keyframes christmasLeftAnimation {
  0% {
    transform-origin: bottom;
    transform: rotate3d(1, 0, 0, 90deg);
  }

  30% {
    transform: rotate3d(1, 0, 0, 0deg);
  }
}

@keyframes christmasRightAnimation {
  30% {
    width: 0px;
    opacity: 0;
  }

  50% {
    width: 200px;
    opacity: 0;
  }

  100% {
    width: 200px;
    opacity: 1;
  }
}
</style>

<template>
  <button
    :disabled="disabled || props.isNotSamePhoneNumber"
    class="verify-code"
    :class="{ 'forbidden-send': disabled || props.isNotSamePhoneNumber }"
    @click.stop.prevent="startSendSmsCode"
  >
    <span class="font-600 get-code text-14px">{{ t("mine.GetCode") }}</span>
    <span
      v-show="count > 0 && timer"
      class="get-code font-bold text-14px countdown-show"
      >({{ count }})</span
    >
  </button>
</template>
<script setup lang="ts">
import { useUserStore } from "~/stores/user"
import * as validate from "~/utils/validate"

const props = withDefaults(
  defineProps<{
    isNotSamePhoneNumber?: boolean
  }>(),
  {
    isNotSamePhoneNumber: false,
  }
)
const emit = defineEmits(["SmsCodeSubmit"])
const user = useUserStore()
const { t } = useLang()
const attrs = useAttrs()
const {
  blogtate: { timer, count },
  startTimer,
  closeTimer,
} = useVerify()
const disabled = computed(
  () => !!timer.value || !validate.isMobile(attrs.value as string)
)
/** 开始发送验证码 */
const startSendSmsCode = async (data = {}) => {
  const res = (await user.GET_MOBILE_CODE({
    areaCode: attrs.areacode,
    mobile: attrs.value,
    smsType: attrs.smstype,
    ...data,
  })) as any
  if (res.code === 0) {
    useNotify({
      title: "Successfully",
      message: "Send Successfully",
      type: "success",
      duration: 1000,
      position: "top-left",
    })
    emit("SmsCodeSubmit")
    startTimer()
  }
}
defineExpose({
  startSendSmsCode,
})
onBeforeUnmount(() => {
  closeTimer()
})
</script>
<style lang="scss">
.get-code {
  color: var(--v-form-get-color);
}
.forbidden-send {
  color: rgba(255, 255, 255, 0.4);
  cursor: not-allowed;
  pointer-events: none;
  .get-code {
    color: var(--v-form-forbidden-text-color);
    margin-left: 8px;
  }
}
</style>

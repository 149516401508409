<script lang="tsx" setup>
import {
  useAiKyc,
  processContentType,
  EnumProcessContentType,
  EnumResultContentType,
  resultContentType,
} from "#imports"
import { LoadingMask } from "#components"

type MessageData = {
  request_id: string
  verification_status: SuftiproVerificationStatus
}

const emits = defineEmits<{
  (event: "verify-success", data: MessageData): void
  (event: "verify-fail", data: MessageData): void
  (event: "message-event", data: MessageData): void
  (event: "iframe-loaded"): void
}>()

const iframeRef = ref<HTMLIFrameElement | null>(null)
const containerId = "shuftipro-iframe-container"
const iframeId = "shuftipro-iframe"
const { getUrl, getAiKycUrlByOfficialApi } = useAiKyc()

function ProcessContent() {
  switch (processContentType.value) {
    case EnumProcessContentType.IFRAME:
      return (
        // @ts-ignore
        <iframe id={iframeId} ref={iframeRef} allow="camera" frameborder="0" />
      )
    case EnumProcessContentType.RESULT:
      switch (resultContentType.value) {
        case EnumResultContentType.Accepted:
          return <div style={{ color: "green" }}>{resultContentType.value}</div>
        case EnumResultContentType.Rejected:
          return <div style={{ color: "red" }}>{resultContentType.value}</div>
      }
  }
}

const isIframeSrcLoading = ref(true)
const onIframeLoad = () => {
  isIframeSrcLoading.value = false
  emits("iframe-loaded")
}
const onIframeError = async () => {
  const awaitResponse = getAiKycUrlByOfficialApi()
  await setIframeSrc(awaitResponse)
}

const onMessage = (event: MessageEvent) => {
  const isShuftiEvent =
    !!event.data?.request_id && !!event.data?.verification_status
  if (isShuftiEvent) {
    const data = event.data as MessageData
    emits("verify-success", data)
    if (data.verification_status === "verification.accepted") {
      emits("verify-fail", data)
    } else {
      emits("message-event", data)
    }
  }
}

const DEFAULT_URL =
  "https://app.shuftipro.com/verification/journey/fXkb4Fau1698292536"

const setIframeSrc = async (requestPromise: ReturnType<typeof getUrl>) => {
  try {
    const response = await requestPromise
    switch (response.data.status) {
      case "request.pending":
        processContentType.value = EnumProcessContentType.IFRAME
        break
      default:
        switch (response.data.status) {
          case "verification.accepted":
            resultContentType.value = EnumResultContentType.Accepted
            lockNx.value = false
            break
          default:
            resultContentType.value = `${response.data.status}`
            lockNx.value = false
            break
        }
        processContentType.value = EnumProcessContentType.RESULT
        isIframeSrcLoading.value = false
        return
    }
    iframeRef.value?.addEventListener("load", onIframeLoad, { once: true })
    iframeRef.value?.setAttribute("src", response?.data?.url)
  } catch (error) {
    iframeRef.value?.setAttribute("src", DEFAULT_URL)
  }
}

onMounted(async () => {
  const awaitGetUrl = getUrl()
  window.addEventListener("message", onMessage)
  iframeRef.value?.addEventListener("error", onIframeError, { once: true })
  await setIframeSrc(awaitGetUrl)
})
onUnmounted(() => {
  try {
    iframeRef.value?.removeEventListener("load", onIframeLoad)
    iframeRef.value?.removeEventListener("error", onIframeError)
    window.removeEventListener("message", onMessage)
  } catch (error) {}
})
</script>
<template>
  <client-only>
    <div :id="containerId">
      <ProcessContent />
      <LoadingMask :is-show="isIframeSrcLoading" :is-fixed="false" />
    </div>
  </client-only>
</template>
<style scoped lang="scss">
#shuftipro-iframe-container {
  position: relative;
  width: 100%;
  height: 610px;
  margin-top: 1em;
  border-radius: 1em;
  overflow: hidden;
  @screen <sm {
    margin-top: 2em;
    height: 80vh;
  }
}
#shuftipro-iframe {
  height: 100%;
  width: 100%;
  border: none;
  overflow: hidden;
  top: 0;
  left: 0;
}
</style>

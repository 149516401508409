<template>
  <ElFormItem class="kyc-form-item-birthday" prop="birthday" :label="t('kyc.birthday')">
    <ElDatePicker
      v-model="form.birthday"
      style="height: inherit"
      :default-value="defaultValue"
      :disabled-date="disabledDate"
      :teleported="true"
      type="date"
      :placeholder="t('kyc.birthday01')"
    />
  </ElFormItem>
</template>
<script lang="ts" setup>
import { ElFormItem, ElDatePicker } from "element-plus"
import { useKycForm } from "#imports"
const { t } = useLang()
const { form } = useKycForm()

const defaultValue = computed(() => {
  return new Date(`${new Date().getFullYear() - 21}/01/01`)
})
// 年龄禁止选择 关于year年的选择，最远为1921年，最近为2002年
const disabledDate = (time: Date) => {
  return (
    time.getFullYear() > new Date().getFullYear() - 21 ||
    time.getFullYear() < 1921
  )
}
</script>
<style lang="scss">
.el-picker-panel__icon-btn {
  --el-datepicker-icon-color: #fff;
}
</style>

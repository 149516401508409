<template>
  <ViewsUserKycFormItemRefuseReason />
  <ViewsUserKycFormItemUserName/>
  <ViewsUserKycFormItemFirstName v-if="firstname"/>
  <ViewsUserKycFormItemMiddleName v-if="middlename"/>
  <ViewsUserKycFormItemLastName v-if="lastname"/>
  <ViewsUserKycFormItemBirthday v-if="birthday"/>
  <ViewsUserKycFormItemRelationStore v-if="nearestbranch"/>
  <ViewsUserKycFormItemNationality v-if="nationality"/>
  <ViewsUserKycFormItemCurrentAddress v-if="currentaddress" />
  <ViewsUserKycFormItemPermanentAddress v-if="permanentaddress" />
  <ViewsUserKycFormItemEmploymentType v-if="employmenttype" />
  <ViewsUserKycFormItemOccupation v-if="occupation" />
  <ViewsUserKycFormItemMainSourceOfFund v-if="mainsource" />
  <ViewsUserKycFormItemIdType v-if="idtype" />
  <ViewsUserKycFormItemImageUploader
    v-model:image-data="form.imgFront"
    field-name="imgFront"
    v-if="imgFront"
  />
  <ViewsUserKycFormItemImageUploader
    v-model:image-data="form.imgHandheld"
    field-name="imgHandheld"
    v-if="imgHandheld"
  />
  <ViewsUserKycFormItemReminderList/>
</template>
<script lang="ts" setup>
import { ViewsUserKycFormItemImageUploader } from "#components"
import { useKycForm } from "#imports"
import { useUserStore } from "~/stores/user"
const userStore = useUserStore()
const { form } = useKycForm()
const visivle = ref()
const firstname = ref(true)
const middlename = ref(true)
const lastname = ref(true)
const birthday = ref(true)
const nearestbranch = ref(true)
const nationality = ref(true)
const currentaddress = ref(true)
const permanentaddress = ref(true)
const employmenttype = ref(true)
const occupation = ref(true)
const mainsource = ref(true)
const idtype = ref(true)
const imgFront = ref(true)
const imgHandheld = ref(true)

const getIsShow = () =>{
  firstname.value = userStore.kycIsVisibleData?.find((item:any) => item.validKey === "first_name")?.showEnabled
  middlename.value = userStore.kycIsVisibleData?.find((item:any) => item.validKey === "middle_name")?.showEnabled
  lastname.value = userStore.kycIsVisibleData?.find((item:any) => item.validKey === "last_name")?.showEnabled
  birthday.value = userStore.kycIsVisibleData?.find((item:any) => item.validKey === "birthday")?.showEnabled
  nearestbranch.value = userStore.kycIsVisibleData?.find((item:any) => item.validKey === "nearest_branch")?.showEnabled
  nationality.value = userStore.kycIsVisibleData?.find((item:any) => item.validKey === "nationality")?.showEnabled
  currentaddress.value = userStore.kycIsVisibleData?.find((item:any) => item.validKey === "current_address")?.showEnabled
  permanentaddress.value = userStore.kycIsVisibleData?.find((item:any) => item.validKey === "permanent_address")?.showEnabled
  employmenttype.value = userStore.kycIsVisibleData?.find((item:any) => item.validKey === "employment_type")?.showEnabled
  occupation.value = userStore.kycIsVisibleData?.find((item:any) => item.validKey === "occupation")?.showEnabled
  mainsource.value = userStore.kycIsVisibleData?.find((item:any) => item.validKey === "main_source")?.showEnabled
  idtype.value = userStore.kycIsVisibleData?.find((item:any) => item.validKey === "id_type")?.showEnabled
  imgFront.value = userStore.kycIsVisibleData?.find((item:any) => item.validKey === "front_photo_of_id_image")?.showEnabled
  imgHandheld.value = userStore.kycIsVisibleData?.find((item:any) => item.validKey === "selfie_with_id_card_image")?.showEnabled
}
const watcher = watch(
  () => form.province,
  () => {
    form.city = undefined
  }
)
watch(
  () => userStore.kycIsVisibleData,
  (val) => {
    if (val) {
      getIsShow()
    }
    
  }
)
onUnmounted(() => {
  watcher()
})
</script>

<template>
  <ElFormItem>
    <CommonButton
      :disabled="isConfirmButtonDisabled"
      :loading="isLoading"
      class="justify-center w-full login-button"
      type="primary"
      @click.prevent="onConfirmButtonClick"
    >
      {{ buttonText }}
    </CommonButton>
  </ElFormItem>
</template>
<script lang="ts" setup>
import { ElFormItem } from "element-plus"
import { useKycForm } from "#imports"
const {
  stepConfirm,
  formRef,
  isLoading,
  isConfirmButtonDisabled,
  currentStep,
} = useKycForm()
const { t } = useLang()

const onConfirmButtonClick = async () => {
  try {
    await stepConfirm()
  } catch (error) {
    console.log(formRef.value)
    console.log(error)
  }
}
const buttonText = computed(() => {
  switch (currentStep.value) {
    case KycSceneStep.FINAL_STEP:
      return t('kyc.confirmBtn')
    default:
      return t('kyc.startBtn')
  }
})
</script>
<style lang="scss" scoped>
.key-type {
  background: #1e202b;
  color: #fff;
  :deep(.el-upload) {
    width: 100%;
  }
  .upload-container {
    background: #262836;
    border: 1px dashed #a5acb8;
    .upload-icon {
      background: linear-gradient(
        180deg,
        #1999ee -9.78%,
        #115bca 26.02%,
        #0b3aa8 111.96%
      );
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
    }
  }
}

.preview-status {
  background: linear-gradient(
    180deg,
    #1999ee -9.78%,
    #115bca 26.02%,
    #0b3aa8 111.96%
  );
  border-radius: 0 0 20px 20px;
}

.reminders-list {
  list-style-type: ". ";
}

.el-select-dropdown__item {
  overflow-wrap: break-word;
  white-space: normal;
  height: auto !important;
  max-width: calc(100vw - 91px);
}

.image-selected {
  margin-bottom: -10px;
}
</style>

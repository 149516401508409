<template>
  <ElFormItem prop="occupation" :label="t('kyc.occupation')">
    <ElSelect
      v-model="form.occupation"
      class="w-full"
      :placeholder="t('public.Select')"
    >
      <ElOption
        v-for="item in Properties.OccupationOptionList"
        :key="`register-form-item-option-${item.code}`"
        :label="`${item.title}`"
        :value="item.code"
      />
    </ElSelect>
  </ElFormItem>
</template>
<script lang="ts" setup>
import { ElFormItem, ElSelect, ElOption } from "element-plus"
import { useKycForm } from "#imports"
const { form, Properties } = useKycForm()
const { t } = useLang()
</script>

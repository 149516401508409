<template>
  <LayoutsFormSection>
    <p>{{ t("wallet.recharge.onlyBindOneTime") }}</p>
    <ElForm
      ref="ruleFormRef"
      :model="ruleForm"
      class="demo-ruleForm"
      status-icon
    >
      <ElFormItem class="flex-1" prop="currency" label="Currency">
        <ElSelect
          v-model="ruleForm.currency"
          class="w-full"
          placeholder="Please select the currency"
        >
          <ElOption
            v-for="item in currencys"
            :key="item"
            :label="item"
            :value="item"
          />
        </ElSelect>
      </ElFormItem>
      <ElFormItem>
        <CommonButton
          :loading="loading"
          class="login-button w-full justify-center"
          type="primary"
          @click.prevent="submit"
        >
          Confirm
        </CommonButton>
      </ElFormItem>
    </ElForm>
  </LayoutsFormSection>
</template>

<script setup lang="ts">
import { ElForm, ElFormItem } from "element-plus"
import type { FormInstance } from "element-plus"
import { useDialog } from "~/composables/useDialog"
import { useWallet } from "~/stores/wallet"

const currencys = ["VND", "TWD"]
const dialog = useDialog()
const { t } = useLang()
const wallet = useWallet()
type RuleFormType = {
  currency: string
}
const loading = shallowRef<boolean>(false)
const ruleFormRef = ref<FormInstance>()
const ruleForm: RuleFormType = reactive({
  currency: "",
})

const submit = () => {
  ruleFormRef?.value?.validate(async (v: boolean) => {
    if (v) {
      loading.value = true
      const { code } = await wallet.BIND_FIAT_WALLET({ ...ruleForm })
      if (code === 0) {
        ruleFormRef?.value?.clearValidate()
        dialog.closeDialog()
        useNotify({
          title: "Successfully",
          message: "Reset Successfully",
          type: "success",
          duration: 1000,
          position: "top-left",
        })
      }
      loading.value = false
      window.location.reload()
    }
  })
}
</script>

<template>
  <ElFormItem :rules="rules" prop="confirmPassword" label="Confirm Password">
    <ElInput
      type="password"
      show-password
      pattern="^[a-zA-Z0-9]{16}$"
      autocomplete="current-password"
      placeholder="Confirm the New Password"
      v-bind="$attrs"
    />
  </ElFormItem>
</template>
<script setup lang="ts">
import { ElFormItem } from "element-plus"
const { t } = useLang()
const attrs = useAttrs()
const checkConfirmPassword = (
  rule: any,
  value: string,
  cb: (arg?: string) => void
) => {
  if (value) {
    if (value === attrs.oldpassword) cb()
    else cb(t("validator.text05"))
  } else cb(t("validator.text06"))
}
const rules = [
  { required: true, validator: checkConfirmPassword, trigger: "blur" },
]
</script>

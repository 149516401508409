<template>
  <ViewsUserKycLayout>
    <StepComponent />
  </ViewsUserKycLayout>
</template>

<script setup lang="tsx">
import {
  ViewsUserKycLayout,
  ViewsUserKycStepAiKyc,
  ViewsUserKycStepFinalReview,
  ViewsUserKycStepGuide,
} from "#components"

const kycFormManager = useKycForm()

function StepComponent() {
  const currentStep = computed(() => kycFormManager.currentStep.value)
  switch (currentStep.value) {
    case KycSceneStep.FIRST_STEP:
      return <ViewsUserKycStepGuide />
    case KycSceneStep.AI_KYC_STEP:
      return <ViewsUserKycStepAiKyc />
    case KycSceneStep.FINAL_STEP:
      return <ViewsUserKycStepFinalReview />
  }
}
</script>

<style lang="scss" scoped>
.key-type {
  background: #1e202b;
  color: #fff;
  :deep(.el-upload) {
    width: 100%;
  }
  .upload-container {
    background: #262836;
    border: 1px dashed #a5acb8;
    .upload-icon {
      background: linear-gradient(
        180deg,
        #1999ee -9.78%,
        #115bca 26.02%,
        #0b3aa8 111.96%
      );
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
    }
  }
}

.preview-status {
  background: linear-gradient(
    180deg,
    #1999ee -9.78%,
    #115bca 26.02%,
    #0b3aa8 111.96%
  );
  border-radius: 0 0 20px 20px;
}

.reminders-list {
  list-style-type: ". ";
}

.el-select-dropdown__item {
  overflow-wrap: break-word;
  white-space: normal;
  height: auto !important;
  max-width: calc(100vw - 91px);
}

.image-selected {
  margin-bottom: -10px;
}
</style>

<template>
  <div
    class="bg-$v-footer-bg transition-all duration-300 pt-12px pb-35px relative flex items-center w-full items-end -mb-20px"
  >
    <div class="consistency-layout-set">
      <div
        class="flex justify-between pb-40px mb-24px border-$v-footer-border border-b-2 <lg:(flex-col-reverse items-center border-none pb-0 mb-18px) <md:(mb-12px)"
      >
        <section
          class="<lg:(w-full flex) <md:(flex-col m-auto w-auto) <sm:(m-auto w-auto)"
        >
          <CommonAsset
            name="images-footer-brand-pagcor"
            alt="brand-icon"
            class="w-250px mt-12px <lg:(w-200px) <md:(w-180px)"
          />
        </section>

        <section
          class="footer-right w-[50%] <xl:(w-[55%]) <lg:(w-full pb-24px mb-24px border-$v-footer-border border-b-1) <sm:(pb-0)"
        >
          <div
            class="flex justify-between align-center <md:(flex-wrap) gap-10px"
          >
            <div
              v-for="(item, idx) in list"
              v-show="!item.hidden"
              :key="item.id"
              class="<2xl:flex-1 <sm:(w-[50%] mb-24px flex-none)"
              :class="{
                follow: idx === 2,
                methods: idx === 3,
              }"
            >
              <p class="text-16px text-$v-footer-title-color font-bold mb-14px">
                {{ item.label ?? "" }}
              </p>
              <ul
                class="flex"
                :class="{ 'flex-col': idx !== 2, '<md:(flex-row)': idx === 3 }"
              >
                <li
                  v-for="(subItem, __idx) in item.children"
                  v-show="!subItem.hidden"
                  :key="subItem.id"
                  class="text-$v-footer-color text-14px font-medium cursor-pointer no-tap-highlight"
                  :class="{
                    'mb-10px': idx !== 2,
                    'mr-20px <sm:(mr-10px)':
                      idx === 2 && __idx !== item.children.length - 1,
                    '<md:(mb-0 mr-12px)':
                      idx === 3 && __idx !== item.children.length - 1,
                  }"
                  @click="pageTo(subItem)"
                >
                  {{ subItem.label || "" }}
                  <CommonAsset
                    v-if="subItem.src"
                    :name="subItem.src"
                    :class="{
                      '<sm:(w-20px)': idx === 2,
                      '<sm:(w-[60px])': idx === 3,
                    }"
                    alt=""
                  />
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <footer class="flex justify-between <lg:(flex-col) <md:(items-center)">
        <p
          class="text-[#B3BAD1] text-14px font-normal <lg:(mb-20px) <md:(font-medium mb-16px text-center)"
        >
          {{ t('footer.copyRight') }}
        </p>
        <ul class="flex items-center font-normal">
          <li
            class="text-$v-footer-color text-14px mr-40px <md:(text-12px font-medium mr-30px text-center)"
          >
            {{ t('footer.privacy') }}
          </li>
          <li
            class="text-$v-footer-color text-14px font-normal <md:(text-12px font-medium text-center)"
          >
            {{ t('footer.contact') }}
          </li>
        </ul>
      </footer>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router"
import { useI18n } from "vue-i18n"

const { t } = useI18n()
const router = useRouter()

const list = computed(() => {
  const gameCategoriesMenu = gameCategoriesMenuState.gameCategoriesMenu.map(
    (item, index) => {
      return {
        id: index,
        label: item.lable,
        key: item.routeName,
      }
    }
  )

  return [
    {
      id: 0,
      label: t('footer.gameCategories'),
      children: gameCategoriesMenu,
    },
    {
      id: 1,
      label: t('footer.help'),
      children: [
        {
          id: 0,
          label:t('footer.terms'),
          key: "Terms",
        },
        // {
        //   id: 1,
        //   label: "About Us",
        //   key: "AboutUs",
        // },
        {
          id: 2,
          label: "FAQ",
          key: "HelpCenter",
        },
        // {
        //   id: 3,
        //   label:t('footer.res'),
        //   key: "ResponsibleGambling",
        // },
        // 未來可能會再打開 先註解掉
        // {
        //   id: 3,
        //   label: "menu.Affiliate",
        //   key: "Affiliate-ID",
        //   options: {
        //     params: {
        //       id: `${user.info?.id ?? 0}`,
        //     },
        //   },
        // },
      ],
    },
    {
      id: 2,
      label: t('footer.follow'),
      children: [
        {
          id: 0,
          src: "images-footer-facebook",
          // url: "https://www.facebook.com/powerbtentcasino",
        },
        {
          id: 1,
          src: "images-footer-instagram",
          // url: "https://www.instagram.com/powerbtentph_official/",
        },
        {
          id: 3,
          src: "images-footer-twitter",
          // url: "https://twitter.com/Powerbtent_ph",
        },
        {
          id: 4,
          src: "images-footer-telegram",
          // url: "https://t.me/Powerbtentgame6688",
        },
      ],
    },
    {
      id: 2,
      label: t('footer.payment'),
      children: [
        {
          id: 0,
          src: "images-footer-gcash",
        },
        {
          id: 1,
          src: "images-footer-grabpay",
        },
        {
          id: 2,
          src: "images-footer-maya",
        },
      ],
    },
  ]
})

const pageTo = (item) => {
  if (item.url) window.open(item.url)
  else if (item.key) router.push({ name: item.key, ...item.options })
}
</script>

<style lang="scss" scoped>
.consistency-layout-set {
  @screen <md {
    padding-bottom: calc(60px + env(safe-area-inset-bottom, 0));
  }
}
@media (max-width: 500px) {
  .follow {
    width: 35%;
  }
  .methods {
    width: 65%;
  }
}
.no-tap-highlight {
  -webkit-tap-highlight-color: transparent;
}
</style>

<template>
  <ElFormItem :label="t('kyc.username')">
    <ElInput :value="username" class="w-full" disabled> </ElInput>
  </ElFormItem>
</template>
<script lang="ts" setup>
import { ElFormItem, ElInput } from "element-plus"
import { useUserStore } from "~/stores/user"
const userStore = useUserStore()
const { t } = useLang()
const username = computed(() => {
  return userStore.info?.username
})
</script>

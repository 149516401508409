<template>
  <div
    id="layout-section"
    ref="layoutSection"
    class="layout-section h-full flex-1"
    :class="{ isNavOpen: isNavOpen }"
    :style="{ paddingTop: height }"
    @scroll="scroll"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, computed } from "vue"
import { useSectionScreen } from "~/composables/useSectionScreen"
import { useAppStore } from "~/stores/app"
import { useWallet } from "~~/stores/wallet"
import { useUserStore } from "~~/stores/user"

const app = useAppStore()
const user = useUserStore()
const route = useRoute()
const { update } = useComponentsContext()
const { update: useAnyDialog } = useAnyDialogIsShowRecord()
const isNavOpen = computed(() => user.isNavBarOpne)
const layoutSection = ref<HTMLBaseElement | any>()
const { current, implementWatch, innerWidth } = useSectionScreen()
provide("section", { current, innerWidth })
const scroll = () => {
  const innerHeight = layoutSection.value?.clientHeight
  const scrollHeight = layoutSection.value?.scrollHeight
  const scrollTop = layoutSection.value?.scrollTop
  if (scrollHeight! > innerHeight!) {
    if (scrollTop! >= 10) update({ isFloatButtonShow: true })
    else update({ isFloatButtonShow: false })
  }
  app.SET_PAGE_SCROLLTOP(route.name, scrollTop)
}

const wallet = useWallet()
const interval = shallowRef<ReturnType<typeof setInterval>>()
const height = ref("50px")

onMounted(() => {
  implementWatch(layoutSection)
  const headerDom = document.getElementById("layout-header") as HTMLElement
  const observer = new ResizeObserver(() => {
    const style = getComputedStyle(headerDom)
    height.value = style.height
  })
  observer.observe(headerDom)

  watch(
    () => route.name,
    (routeName) => {
      interval.value && clearInterval(interval.value)
      switch (routeName) {
        case "GameRoom":
          useAnyDialog("GameRoom-GameRoom", true)
          interval.value = setInterval(wallet.GET_ONLY_USER_BALANCE, 10000)
          break
        default:
          useAnyDialog("GameRoom-GameRoom", false)
          break
      }
    },
    {
      immediate: true,
    }
  )
  watch(
    () => user.isLogin,
    () => {
      if (!user.isLogin) clearInterval(interval.value)
    }
  )
})
</script>
<style lang="scss" scoped>
.layout-section {
  width: calc(100% - 70px);
  &.isNavOpen {
    width: calc(100% - 200px);
  }
}
</style>

<template>
  <div>
    <div>{{ step }} / 3</div>
    <component
      :is="componentTypeMap[step]"
      :has-step="true"
      :before-close="beforeClose"
    />
  </div>
</template>

<script setup lang="ts">
import { dialogState, useDialog } from "~/composables/useDialog"

const ChangeMobile = resolveComponent("ViewsUserChangeMobile")
const ChangePassword = resolveComponent("ViewsUserChangePassword")
const KycForm = resolveComponent("ViewsUserKycMainForm")
const step = computed(() => dialogState.type?.split(":")[1] as string)

const componentTypeMap: { [key: string]: any } = {
  "1": ChangeMobile,
  "2": ChangePassword,
  "3": KycForm,
}

// 关闭之前
const beforeClose = () => {
  useDialog().closeDialog()
}
</script>

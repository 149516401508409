/**
 * 主要针对 section size
 */
import { useElementBounding } from "@vueuse/core"
import { useHomeStore } from "~/stores/home"
import { defaultScreenConfig, Size } from "~/types/enum"
import type { ScreenSize } from "~/types/enum"

export const useSectionScreen = () => {
  const el = shallowRef()
  const innerWidth = shallowRef(0)
  const current = ref<ScreenSize>(Size.SMALL)
  const visible = computed(() => useHomeStore().isVisible)
  const getSize = () => {
    const { width } = useElementBounding(el)
    innerWidth.value = width.value
    const {
      [Size.SMALL]: sm,
      [Size.MEDIUM]: md,
      [Size.LARGE]: lg,
      [Size.EXTRA_LARGE]: xl,
    } = defaultScreenConfig
    if (width.value < Number(sm)) return Size.SMALL
    if (width.value < Number(md)) return Size.MEDIUM
    if (width.value < Number(lg)) return Size.LARGE
    if (width.value < Number(xl)) return Size.EXTRA_LARGE
    return Size.EXTRA_2LARGE
  }

  const onWindowResize = () => {
    current.value = getSize()
  }

  const implementWatch = (dom: HTMLBaseElement | any) => {
    el.value = dom.value
    window.addEventListener("resize", onWindowResize)

    nextTick(() => {
      onWindowResize()
    })
  }

  onUnmounted(() => {
    if (typeof window === "undefined") return
    window.removeEventListener("resize", onWindowResize)
  })
  watch(
    () => visible.value,
    () => {
      onWindowResize()
    }
  )
  return {
    getSize,
    current,
    onWindowResize,
    implementWatch,
    innerWidth,
  }
}

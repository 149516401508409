<template>
  <LayoutsFormSection>
    <ElForm
      ref="ruleFormRef"
      :model="ruleForm"
      class="demo-ruleForm"
      status-icon
    >
      <FormMobileItem
        v-model="ruleForm.mobile"
        :areacode="ruleForm.areaCode"
        :formatter-fn="mobileFormatters"
        @area-change="onAreaChange"
      >
        <FormVerifyButton
          smstype="OTHER"
          :areacode="ruleForm.areaCode"
          :value="ruleForm.mobile"
        />
      </FormMobileItem>
      <FormVerifyCode v-model="ruleForm.verifyCode" />
      <ElFormItem>
        <CommonButton
          :loading="loading"
          class="login-button w-full justify-center"
          type="primary"
          @click.prevent="submitLogin"
        >
          {{ hasStep ? "Next" : "Confirm" }}
        </CommonButton>
      </ElFormItem>
    </ElForm>
  </LayoutsFormSection>
</template>

<script setup lang="ts">
import { ElForm, ElFormItem } from "element-plus"
import type { FormInstance } from "element-plus"
import { useUserStore } from "~/stores/user"
import { useDialog } from "~/composables/useDialog"

const props = defineProps<{ hasStep: boolean }>()
const hasStep = computed(() => props.hasStep)
const user: any = useUserStore()
type RuleFormType = {
  areaCode: string
  mobile: number | string
  verifyCode: number | string
}
const loading = shallowRef<boolean>(false)
const ruleFormRef = ref<FormInstance>()
const ruleForm: RuleFormType = reactive({
  areaCode: "+886",
  mobile: user.info?.mobile,
  verifyCode: "",
})
const mobileFormatters = (val: string) => {
  ruleForm.mobile = formatters.mobile(val.toString())
}
const submitLogin = () => {
  ruleFormRef?.value?.validate(async (v: boolean) => {
    if (v) {
      loading.value = true
      const code = await user.RESET_MOBILE({ ...ruleForm })
      if (code === 0) {
        ruleFormRef?.value?.clearValidate()
        useNotify({
          title: "Successfully",
          message: "Reset Successfully",
          type: "success",
          duration: 1000,
          position: "top-left",
        })
        if (hasStep.value) useDialog().openDialog("verifyAccountStep:2")
        else useDialog().closeDialog()
        user.GET_USER_PROFILE()
      }
      loading.value = false
    }
  })
}

const onAreaChange = (value: string) => {
  ruleForm.areaCode = value
}
</script>

<template>
  <ElFormItem prop="idType" :label="t('kyc.idtype')">
    <ElSelect
      v-model="form.idType"
      class="w-full"
      :placeholder="t('public.Select')"
      popper-class="id-type-class"
    >
      <ElOption
        v-for="item in Properties.KycTypeOptionList"
        :key="`register-form-item-option-${item.code}`"
        :label="`${item.title}`"
        :value="item.code"
        :disabled="item.disabled"
        :class="{
          disabled: item.disabled,
          hidden: item.hidden,
        }"
      >
      </ElOption>
    </ElSelect>
  </ElFormItem>
</template>
<script lang="ts" setup>
import { ElFormItem, ElSelect, ElOption } from "element-plus"
import { useKycForm } from "#imports"
const { form, Properties } = useKycForm()
const { t } = useLang()
</script>
<style lang="scss" scoped>
.key-type {
  background: #1e202b;
  color: #fff;
  :deep(.el-upload) {
    width: 100%;
  }
  .upload-container {
    background: #262836;
    border: 1px dashed #a5acb8;
    .upload-icon {
      background: linear-gradient(
        180deg,
        #1999ee -9.78%,
        #115bca 26.02%,
        #0b3aa8 111.96%
      );
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
    }
  }
}

.preview-status {
  background: linear-gradient(
    180deg,
    #1999ee -9.78%,
    #115bca 26.02%,
    #0b3aa8 111.96%
  );
  border-radius: 0 0 20px 20px;
}

.reminders-list {
  list-style-type: ". ";
}

.el-select-dropdown__item {
  overflow-wrap: break-word;
  white-space: normal;
  height: auto !important;
  max-width: calc(100vw - 91px);
}
</style>
<style lang="scss">
.id-type-class {
  *.disabled {
    color: #a5acb8;
    text-decoration: line-through;
  }
  *.hidden {
    display: none;
  }
}
</style>

<template>
  <div>
    <VitePwaManifest />
    <LayoutsSeoManager>
      <LayoutsInitialize>
        <client-only>
          <NuxtLoadingIndicator :height="5" :duration="3000" :throttle="400" />
        </client-only>

        <div v-if="!isDev && isInMaintenance">
          <LazyLayoutsMaintenacePage />
          <LazyCommonLiveChatWidgetButton />
        </div>
        <LayoutsSideEffects v-else>
          <div
            class="layouts-default-full h-full w-[100vw] flex bg-$v-bg-page-bg-primary"
          >
            <LazyLayoutsNavBar />
            <LayoutsSection>
              <LayoutsHeader />
              <section class="w-full bg-full overflow-x-hidden layoutBg">
                <LayoutsSectionInner>
                  <slot />
                </LayoutsSectionInner>
              </section>
              <LayoutsFooter />
            </LayoutsSection>
            <client-only>
              <!-- <LayoutsCookieConsent /> -->
              <LazyLayoutsAgeConfirmDialog v-if="!isAgeCheckDisabled" />
              <LazyViewsDailyCheckInDialog />
              <LazyViewsDailyCheckInAnimationDialog />
              <LazyCommonLiveChatWidgetButton />
              <LazySvgSprite />
              <ViewsUser />
              <AppDownloadEntry />
              <PromotionPopup />
              <CommonDialog />
              <CommonHighlightMessageHeader />
              <CommonPopup />
              <ActivityRaffleTicketRafflePopup />
            </client-only>
          </div>
        </LayoutsSideEffects>
      </LayoutsInitialize>
    </LayoutsSeoManager>
  </div>
</template>
<script setup lang="ts">
import { useAppStore } from "~/stores/app"
import {
  CommonDialog,
  CommonPopup,
  CommonHighlightMessageHeader,
  ActivityRaffleTicketRafflePopup,
} from "#components"

const route = useRoute()
const runtimeConfig = useRuntimeConfig()
const isAgeCheckDisabled = computed(
  () => !!Number(runtimeConfig.public.IS_AGE_CHECK_DISABLED || 0)
)

const isInMaintenance = computed(() => {
  const maintenance = useAppStore()
    .config // @ts-ignore
    ?.find((i) => i.id === 31)
  if (maintenance) {
    const { endTime } = JSON.parse(maintenance.value)
    try {
      const info = JSON.parse(maintenance?.value)
      const isNow = Date.now()
      if (info.status === 1 && isNow < Number(endTime) * 1000) {
        return true
      }
      return false
    } catch (e) {
      console.error(e)
      return false
    }
  }
  return false
})

const getLang = () =>{
  const lang = typeof window !== 'undefined' ? localStorage.getItem('lang') : null;
  if (lang) {
    useAppStore().SET_LANG(lang)
  }
  
}
onMounted(()=>{
  getLang()
})
</script>
<style scoped lang="scss">
.layoutBg{
  background: url('../assets/default/layoutBg.png') no-repeat center right;
  background-size:20% ;
}
</style>

<template>
  <Teleport to="body" v-if="isShow">
    <div
      @click.self="handleClose"
      class="mask fixed z-11000 left-0 top-0 w-full h-full flex justify-center"
    >
      <div
        class="popup-body w-280px h-420px md:w-453px md:h-180px rounded-8px animate__animated animate__flipInY"
        :class="{
          'animate__animated animate__flipOutY': startingClose,
        }"
      >
        <p class="mt-188px ml-45px md:ml-65px md:mt-30px number text-18px">
          {{ popupProps.ticket?.ticket_id }}
        </p>
        <button
          class="absolute w-[111px] h-[25px] right-[10px] top-[118px] <sm:w-[156px] <sm:h-[36px] <sm:left-[62px] <sm:top-[247px] cursor-pointer"
          @click.prevent.stop="goRaffle"
        />
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { useRaffleTicketManager } from "#imports"
import { shallowRef } from "vue"
const startingClose = shallowRef(false)
const handleClose = () => {
  startingClose.value = true
  setTimeout(() => {
    useRaffleTicketManager().raffleTicketPopupProps.show = false
    startingClose.value = false
  }, 600)
}

const goRaffle = async () => {
  await navigateTo({ name: "RaffleTicket" })
  handleClose()
}

const popupProps = computed(
  () => useRaffleTicketManager().raffleTicketPopupProps
)

const isShow = computed(
  () => popupProps.value.show && !!popupProps.value.ticket
)
</script>
<style scoped lang="scss">
.mask {
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.number {
  font-family: "Lemon Milk", sans-serif;
  background: linear-gradient(
    90.27deg,
    #e8cf96 5.25%,
    #fffed5 50.2%,
    #f4e1a0 94.22%
  );
  text-stroke: 1px rgba(255, 219, 116, 0.3);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.popup-body {
  background: no-repeat top center/100% 100%
    url("~/assets/images/activity/raffle-ticket/raffale-popup-bg-h5.png");
}

@screen md {
  .popup-body {
    background: no-repeat top center/100% 100%
      url("~/assets/images/activity/raffle-ticket/raffle-popup-bg.png");
  }
}
</style>

<template>
  <ElFormItem :rules="rules" prop="password" :label="t('mine.Password')">
    <ElInput
      type="password"
      show-password
      placeholder="Enter the Password"
      pattern="^[a-zA-Z0-9]{16}$"
      autocomplete="current-password"
      v-bind="$attrs"
    />
  </ElFormItem>
</template>
<script setup lang="ts">
import { ElFormItem } from "element-plus"
import { ElInput } from "#components"
const { t } = useLang()
const props = withDefaults(
  defineProps<{
    validateKey: "checkPassword" | "checkPasswordOld" | "checkPasswordSignIn"
  }>(),
  {
    validateKey() {
      return "checkPassword"
    },
  }
)
const { checkPassword, checkPasswordOld, checkPasswordSignIn } = useCheck()

const currentValidate = computed(() => {
  switch (props.validateKey) {
    case "checkPassword":
      return checkPassword
    case "checkPasswordOld":
      return checkPasswordOld
    case "checkPasswordSignIn":
      return checkPasswordSignIn
    default:
      return checkPassword
  }
})

const rules = computed(() => [
  {
    required: true,
    validator: currentValidate.value,
    trigger: "blur",
  },
])
</script>

<template>
  <div class="layout-side-effects">
    <slot />
    <LazyLoadingMask :is-show="isShow" />
  </div>
</template>

<script lang="ts" setup>
const isShow = shallowRef(true) as Ref<boolean> // 首次进入遮罩层
onMounted(() => {
  // 渲染完成关闭
  isShow.value = false
})
</script>

interface PromotionType {
  [key: number]: string
}

export type ActivityPopupItem = {
  activityType: number
  href: string
  imageH5: string
  imagePc: string
}
// 1-充值送，2-注册送，3-救济金，4-签到，5-特定活动, 6-自訂活動
export const PromotionName: PromotionType = {
  1: "Deposit Bonus",
  2: "Register Bonus",
  3: "Rescue Bonus",
  4: "Daily Check-in Bonus",
  5: "Christmas",
  6: "Custom activity",
}

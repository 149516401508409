<template>
  <LayoutsFormSection>
    <ElForm
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      status-icon
    >
      <ElFormItem prop="categoryTransfer" label="Account Type">
        <ElSelect
          v-model="ruleForm.categoryTransfer"
          class="w-full"
          :placeholder="t('public.Select')"
        >
          <ElOption
            v-for="item in categoryList"
            :key="`register-form-item-option-${item.categoryTransfer}`"
            :label="`${item.categoryTransferName}`"
            :value="item.categoryTransfer"
          >
          </ElOption>
        </ElSelect>
      </ElFormItem>
      <ElFormItem
        v-if="typeInfo && typeInfo.typeList"
        prop="accountType"
        :label="t('bet.betrecord.Type')"
      >
        <ElSelect
          v-model="ruleForm.accountType"
          :teleported="false"
          class="w-full"
          :placeholder="t('mine.profile.PleaseSelect')"
          @change="changeType"
        >
          <ElOption
            v-for="item in typeInfo.typeList"
            :key="item.type"
            :label="item.name"
            :value="item.type"
          />
        </ElSelect>
      </ElFormItem>
      <template v-if="String(ruleForm.categoryTransfer) === '20'">
        <ElFormItem prop="currency" label="Currency">
          <ElSelect
            v-model="ruleForm.currency"
            class="w-full"
            :placeholder="t('mine.profile.PleaseSelect')"
          >
            <ElOption label="ETH" value="ETH" />
            <ElOption label="TRX" value="TRX" />
            <ElOption label="USDT-ERC20" value="USDT.ERC20" />
            <ElOption label="USDT-TRC20" value="USDT.TRC20" />
          </ElSelect>
        </ElFormItem>
        <ElFormItem prop="address" label="Address">
          <ElInput
            v-model.trim="ruleForm.address"
            :placeholder="t('additional.Address')"
          />
        </ElFormItem>
      </template>
      <template v-else>
        <ElFormItem prop="currency" label="Currency">
          <ElSelect
            v-model="ruleForm.currency"
            class="w-full"
            :placeholder="t('mine.profile.PleaseSelect')"
          >
            <ElOption label="USD" value="USD" />
            <ElOption label="VND" value="VND" />
            <ElOption label="TWD" value="TWD" />
          </ElSelect>
        </ElFormItem>
        <ElFormItem prop="realName" label="Account Name">
          <ElInput
            v-model="ruleForm.realName"
            clearable
            :placeholder="t('additional.realName')"
          />
        </ElFormItem>
        <ElFormItem prop="accountNumber" label="Account Number">
          <ElInput
            v-model.trim="ruleForm.accountNumber"
            :placeholder="t('additional.accountNumber')"
            @input="handleAccountNumber"
          />
        </ElFormItem>
      </template>

      <ElFormItem>
        <CommonButton
          :loading="loading"
          class="justify-center w-full login-button"
          type="primary"
          @click.prevent="submitLogin"
        >
          Confirm
        </CommonButton>
      </ElFormItem>
    </ElForm>
  </LayoutsFormSection>
</template>

<script setup lang="ts">
import { ElForm, ElFormItem, ElSelect, ElInput, ElOption } from "element-plus"
import type { FormInstance, FormRules } from "element-plus"
import { useDialog } from "~/composables/useDialog"
import { useWallet } from "~/stores/wallet"
import { useCard } from "~/stores/card"

const card = useCard()
const { checkAccountNumber } = useCheck()

const rules = computed<FormRules>(() => ({
  categoryTransfer: [
    {
      required: true,
      message: "Please Select",
      trigger: "change",
    },
  ],
  accountType: [
    {
      required: true,
      message: "Please Select",
      trigger: "change",
    },
  ],
  currency: [
    {
      required: true,
      message: "Please Select",
      trigger: "change",
    },
  ],
  accountNumber: [
    {
      required: true,
      validator: checkAccountNumber,
    },
  ],
  realName: [{ required: true, trigger: "blur" }],
}))
const { t } = useLang()
const wallet = useWallet()

// 提现类型列表
const categoryList = computed(() => {
  // const { code } = props
  const code = "1"
  const list = withdrawChannelList.value
  const newList: any = []
  list.forEach((item) => {
    if (item.categoryCurrency.toString() === code) {
      newList.push(item)
    }
  })
  return newList
}) as any as Ref<WithdrawType[]>

type RuleFormType = {
  categoryTransfer: string
  accountType: string
  realName: string
  accountNumber: string
  currency: string
  address: string
}
const loading = shallowRef<boolean>(false)
const ruleFormRef = ref<FormInstance>()
const ruleForm: RuleFormType = reactive({
  categoryTransfer: "",
  accountType: "",
  realName: "",
  accountNumber: "",
  currency: "",
  address: "",
})

type ParamsType = {
  address?: string
  categoryCurrency: string | number
  categoryTransfer: number | string
  currency: string
  accountNo?: number | string
}
const submitLogin = () => {
  ruleFormRef?.value?.validate(async (v: boolean) => {
    if (v) {
      loading.value = true
      const { categoryTransfer } = typeInfo.value
      const params: ParamsType = {
        categoryCurrency: typeInfo.value?.categoryCurrency,
        categoryTransfer,
        currency: ruleForm.currency,
      }
      switch (categoryTransfer) {
        case 1:
        case 2:
          break
        case 3:
        case 5:
        case 6:
        case 8:
        case 9:
        case 12:
          params.address = JSON.stringify({
            accountNumber: ruleForm.accountNumber,
            accountNo: ruleForm.realName,
          })
          break
        case 4:
          params.address = JSON.stringify({
            accountType: ruleForm.accountType,
            // accountNo: ruleForm.accountNo,
          })
          break
        case 20:
          params.address = JSON.stringify({
            address: ruleForm.address,
            currency: ruleForm.currency,
          })
          params.categoryCurrency = 0
      }
      const code = await card.CREATE_CARD(params)
      if (code === 0) {
        ruleFormRef?.value?.clearValidate()
        useDialog().closeDialog()
        useNotify({
          title: "Successfully",
          message: "Created Successfully",
          type: "success",
          position: "top-left",
        })
      }
      loading.value = false
    }
  })
}

const handleAccountNumber = () => {
  const regex = /^[0-9]+$/
  if (!regex.test(ruleForm.accountNumber)) {
    ruleForm.accountNumber = ruleForm.accountNumber.replace(/[^0-9]/g, "")
  }
}

const withdrawChannelList = computed(() => wallet.withdrawChannelList)
// 以及选中后的详情
const typeInfo = computed(
  () =>
    categoryList.value?.find(
      (item: WithdrawType) =>
        ruleForm.categoryTransfer.toString() ===
        item.categoryTransfer.toString()
    ) ?? {}
) as unknown as Ref<WithdrawType>
const changeType = () => {
  ruleForm.accountNumber = ""
  ruleForm.realName = ""
}
</script>

<style lang="scss" scoped></style>

<template>
  <ElFormItem :rules="rules" prop="verifyCode" :label="t('sign.verification')">
    <ElInput
      inputmode="numeric"
      type="code"
      maxlength="6"
      :placeholder="t('mine.EnterCodeVerification')"
      v-bind="$attrs"
      ref="verifyInput"
    >
      <template #suffix>
        <slot />
      </template>
    </ElInput>
  </ElFormItem>
</template>
<script setup lang="ts">
import { useAppStore } from "~/stores/app"
const emit = defineEmits(["init"])
const app = useAppStore()

const { t } = useLang()
const validatorText13 = t("validator.text13")
const validatorText08 = t("validator.text08")
const { checkVerify } = useCheck()
const verifyInput = ref<HTMLElement>()
const rules = computed(() => [
  { 
    required: app.registerItemData?.find((item:any) => item.validKey === "password")?.requiredEnabled,
    validator: (
      rule: any,
      value: string,
      cb: (arg?: string) => void
    ) => {
      if (value) {
        if (isVerify(value)) cb()
        else cb(validatorText13)
      } else if(app.registerItemData?.find((item:any) => item.validKey === "password")?.requiredEnabled){
        cb(validatorText08)
      }
    },
    trigger: "blur" },
])

onMounted(() => {
  if (verifyInput.value) {
    emit("init", verifyInput.value)
  }
})
</script>

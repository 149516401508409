<script lang="ts" setup>
const route = useRoute()

const title = computed(() => {
  switch (route.query?.dialogType) {
    case "signIn":
      return "Sign Up"
    case "PwdSign":
      return "Login"

    default:
      return "Login"
  }
})

const className = computed(() => {
  const device = useDeviceSymbol()
  switch (device) {
    case "d":
      return "text-center text-2xl font-bold m-5 sm:m-10"
    case "m": {
      switch (route.query?.dialogType) {
        case "signIn":
          return "text-center text-md font-bold p-[1em]"
      }
      return "text-center text-md font-bold sm:mt-10"
    }
    default:
      return "text-center text-2xl font-bold sm:mt-10"
  }
})
</script>

<template>
  <p
    class="text-center text-xl font-bold my-5 <sm:my-3 <sm:text-sm <sm:font-bold !important"
  >
    {{ title }}
  </p>
</template>

<style lang="scss" scoped></style>

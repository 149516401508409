import { defineStore } from "pinia"
import type { StoreTypes } from "~~/types/store"

const initialValue: StoreTypes.Persist.State = {
  isLogin: null,
  info: null,
  showZeroBalance: false,
  showFiatBalance: true,
}

export const usePersistStore = defineStore("persist", {
  persist: true,
  state: (): StoreTypes.Persist.State => {
    return { ...initialValue, isLogin: !!useToken().getToken() }
  },
})

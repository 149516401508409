<template>
  <CommonAsset
    name="images-footer-pagcor-21-icon"
    class="mb-23px"
    alt="pagcor"
  />
</template>

<script setup lang="ts"></script>

<style scoped></style>

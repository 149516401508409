<template>
  <div
    class="max-h-90vh sm:h-735px relative w-760px <sm:h-80vh <lg:w-9/10 m-0-auto banner-dialog flex items-stretch rounded-[2%] overflow-hidden"
  >
    <section
      class="login-left-area relative w-419px <md:hidden overflow-hidden"
    >
      <Swiper
        v-if="Home.loginBannerList.length"
        class="login-dialog-carousel w-full h-full left-0 top-0 !absolute"
        :slides-per-view="1"
        :navigation="true"
        :loop="true"
        :autoplay="{
          disableOnInteraction: false,
        }"
        :pagination="{
          clickable: true,
        }"
        :modules="[Navigation, Pagination, Autoplay]"
      >
        <SwiperSlide
          v-for="item in Home.loginBannerList"
          :key="item?.img"
          class="h-full"
        >
          <CommonAsset
            class="h-full w-full select-none"
            :src="item?.img"
            alt=""
          />
        </SwiperSlide>
      </Swiper>
      <CommonAsset
        v-else
        name="default-login-banner"
        class="h-full w-full left-0 top-0 object-fill"
      />
    </section>
    <div
      class="text-12px absolute z-10 right-10px top-12px flex justify-end cursor-pointer"
    >
      <CommonAsset name="icons-close" @click.stop="close" />
    </div>
    <section class="form-container relative h-full flex-1 px-12px py-12px">
      <div
        class="w-full h-full overflow-y-scroll rounded-[2%] hide-scrollbar-mobile"
        :class="useHideScrollbar"
      >
        <section class="h-full flex flex-col justify-start items-center">
          <CommonAsset
            class="mt-12px max-h-27px"
            :src="PLAT_LOGO"
            alt="plat-logo"
          />
          <slot />
        </section>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue"
import { Navigation, Pagination, Autoplay } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { useHomeStore } from "~/stores/home"
import { useDialog } from "~/composables/useDialog"
import { useHideScrollbar } from "~/composables/useHideScrollbar"

const Home = useHomeStore()
const { PLAT_LOGO } = useConfig()
const close = () => {
  useDialog().closeDialog()
}

setTimeout(() => {
  if (!Home.loginBannerList.length) Home.GET_BANNER({ type: 2 })
})
</script>

<style lang="scss" scoped>
.banner-dialog {
  background: #262836;
  :deep(.el-carousel__container) {
    height: 100%;
  }
  .form-container {
    background: #262836;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.03);
    box-sizing: border-box;
  }
  :deep(.swiper) {
    .swiper-button-next,
    .swiper-button-prev {
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.3);
      width: 26px;
      height: 26px;
      border-radius: 100%;
      &:after {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .swiper-pagination-bullet-active {
      background-color: #fff;
      width: 10px;
      height: 10px;
    }
  }
}
@screen <md {
  .banner-dialog {
    .form-container {
    }
  }
}
</style>

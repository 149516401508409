<template>
  <LayoutsFormSection>
    <ElForm
      ref="ruleFormRef"
      :model="ruleForm"
      class="demo-ruleForm"
      status-icon
    >
      <FormMobileItem
        v-model="ruleForm.mobile"
        :areacode="ruleForm.areaCode"
        :formatter-fn="mobileFormatters"
        @area-change="onAreaChange"
      >
        <FormVerifyButton
          smstype="FORGOT"
          :areacode="ruleForm.areaCode"
          :value="ruleForm.mobile"
        />
      </FormMobileItem>
      <FormVerifyCode v-model="ruleForm.verifyCode" />
      <FormPassWordItem
        v-model="ruleForm.password"
        validate-key="checkPassword"
        placeholder="Enter the New Password"
        autocomplete="new-password"
      />
      <FormComfirmPassWordItem
        v-model="ruleForm.confirmPassword"
        :oldpassword="ruleForm.password"
        placeholder="Confirm the New Password"
      />
      <ElFormItem>
        <CommonButton
          :loading="loading"
          class="login-button w-full justify-center"
          type="primary"
          @click.prevent="submitLogin"
        >
          Confirm
        </CommonButton>
      </ElFormItem>
    </ElForm>
  </LayoutsFormSection>
</template>

<script setup lang="ts">
import { ElForm, ElFormItem } from "element-plus"
import type { FormInstance } from "element-plus"
import { useUserStore } from "~/stores/user"
import { useDialog } from "~/composables/useDialog"
import * as validate from "~/utils/validate"
const dialog = useDialog()

const user = useUserStore()
type RuleFormType = {
  password: string
  areaCode: string
  mobile: number | string
  verifyCode: number | string
  confirmPassword: string
}
const loading = shallowRef<boolean>(false)
const ruleFormRef = ref<FormInstance>()
const ruleForm: RuleFormType = reactive({
  password: "",
  areaCode: "+886",
  mobile: "",
  verifyCode: "",
  confirmPassword: "",
})

const submitLogin = () => {
  ruleFormRef?.value?.validate(async (v: boolean) => {
    if (v) {
      loading.value = true
      const mobile = correctPhoneNumberFormat(ruleForm.mobile)
      const { code } = await user.FORGET_PASSWORD({ ...ruleForm, mobile })
      if (code === 0) {
        ruleFormRef?.value?.clearValidate()
        ruleForm.password = ""
        useNotify({
          title: "Successfully",
          message: "Reset Successfully",
          type: "success",
          duration: 1000,
          position: "top-left",
        })
        if (user.isLogin) {
          await user.LOGOUT(async () => {
            await navigateTo({ name: "Home" })
            await dialog.openDialog("PwdSign")
          })
        } else {
          dialog.openDialog("PwdSign")
        }
      }
      loading.value = false
    }
  })
}
const mobileFormatters = (val: string) => {
  ruleForm.mobile = formatters.mobile(val.toString())
}

// 手机号 验证码发送
const correctPhoneNumberFormat = (number: string | number) => {
  let phoneNumber = number.toString()

  if (validate.isMobile(phoneNumber)) {
    if (phoneNumber.toString().charAt(0) === "0")
      phoneNumber = phoneNumber.slice(1, phoneNumber.length)
  }

  return phoneNumber
}

const onAreaChange = (value: string) => {
  ruleForm.areaCode = value
}
</script>

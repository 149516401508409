import { useSvgStore } from "~/stores/svg"
import { useAppStore } from "~/stores/app"
import { useGame } from "~/stores/game"
import { useAssetStore } from "~/stores/asset"
import { useCheckInStore } from "~/stores/checkIn"
import { useUserStore } from "~/stores/user"
import { useSocketStore } from "~/stores/socket"
import { useGameTypeManager } from "~/composables/useGameTypeManager"
import { StoreTypes } from "~/types/store"
import { useGoogle } from "~/stores/google"
import { log } from "console"

export const useGlobalInit = () => {
  const svgStore = useSvgStore()
  const appStore = useAppStore()
  const gameStore = useGame()
  const assetStore = useAssetStore()
  const socketStore = useSocketStore()
  const userStore = useUserStore()
  const checkInStore = useCheckInStore()
  const gameTypeManager = useGameTypeManager()
  const googleStore = useGoogle()
  const raffleTicketManager = useRaffleTicketManager()

  async function init() {
    if (process.client) {
      raffleTicketManager.raffleTicketStorageMapReset()
    }
    const lang = typeof window !== 'undefined' ? localStorage.getItem('lang') : null;
    if (lang) {
      appStore.SET_LANG(lang)
    }
    watchEffect(() => {
      const isSocketExisted = !!socketStore.ws
      if (process.client) {
        if (userStore.isLogin && !isSocketExisted) {
          socketStore.CONNECT_SOCKET()
        }
        if (!userStore.isLogin && isSocketExisted) {
          socketStore.CLOSE_SOCKET()
        }
      }
    })
    await Promise.allSettled([
      svgStore.INIT_SVG_VIEWBOX_MAP(),
      appStore.GET_CONFIG(),
      appStore.GET_DICTIONARY(),
      gameStore.GET_GAME_BANNER_LIST(),
      assetStore.INIT_ASSET_LIST(),
      checkInStore.GET_CURRENT_ACTIVITY(false),
      gameTypeManager.getGameBannerList(),
      appStore.GET_REGISTER_CONFIG('member_regist')
    ])
  }

  async function thirdPartyAccessCheck() {
    const query = getHybridQuery()
    const thirdType = useStorage().getValue("thirdType")
    const userStatusInfo = await (async () => {
      if (userStore.isLogin) {
        await googleStore.GET_PROVIDER_INFO()
      }
      return googleStore.providerInfo
    })()
    const lastPath = localStorage.getItem(__thirdparty_redirect_url) || "/"
    const lastJson = JSON.parse(
      localStorage.getItem(__thirdparty_search_before_redirect) || "{}"
    )
    if (query.code && thirdType) {
      await navigateTo(lastPath, {
        replace: true,
      })
      if (userStatusInfo.googleLoginEmail) {
        useNotify({
          type: "warning",
          message: "You have already bound Google account",
          duration: 1000,
          position: "top-left",
        })
        return
      }
      const promoCodeManager = usePromoCodeManager()
      const promoCode = (() => {
        return (
          promoCodeManager.getPromoCode() ||
          promoCodeManager.getPromoCode("manualInputPromoCode") ||
          ""
        )
      })()
      const thirdpartyLoginType = (() => {
        if (query.scope && query.scope.includes("googleapis")) {
          // google
          return 1
        }
        return null
      })()

      if (thirdpartyLoginType) {
        const ActionName = (() => {
          if (userStore.isLogin) {
            return "THIRDPARTY_BINDING"
          }
          return "THIRDPARTY_LOGIN"
        })()
        const params: StoreTypes.User.providerLogin = (() => {
          switch (ActionName) {
            case "THIRDPARTY_BINDING":
              return {
                thirdType: `${thirdpartyLoginType}`,
                authCode: query.code,
                device: useDeviceSymbol(),
              }
            case "THIRDPARTY_LOGIN":
              return {
                thirdType: `${thirdpartyLoginType}`,
                authCode: query.code,
                device: useDeviceSymbol(),
                promoCode,
                activityLaunchHelpId: lastJson.activityLaunchHelpId,
              }
          }
        })()
        try {
          const { code } = await (() => {
            switch (ActionName) {
              case "THIRDPARTY_BINDING":
                return userStore.BINDING(params)
              case "THIRDPARTY_LOGIN":
                return userStore.SAVE_PROVIDER_INFO(params)
            }
          })()
          if (code === 0) {
            switch (ActionName) {
              case "THIRDPARTY_BINDING": {
                useNotify({
                  type: "success",
                  message: "Google Binding Success",
                  duration: 1000,
                  position: "top-left",
                })
                break
              }
              case "THIRDPARTY_LOGIN": {
                useNotify({
                  type: "success",
                  message: "Google Login Success",
                  duration: 1000,
                  position: "top-left",
                })
                await openKycAfterAuth()
                break
              }
            }
            // 手动输入的要清掉
            promoCodeManager.clearPromoCode("manualInputPromoCode")
          }
        } catch (error) {
          useNotify({
            type: "warning",
            message: (error as Error).message,
            duration: 1000,
            position: "top-left",
          })
        }
      }
    }
  }

  return {
    init,
    thirdPartyAccessCheck,
  }
}

<template>
  <LayoutsFormSection>
    <ElForm
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      status-icon
    >
      <ElFormItem prop="googleCode" label="Code Verification">
        <ElInput
          v-model="ruleForm.googleCode"
          type="number"
          placeholder="Enter The Six Digit Code To Verify"
        />
      </ElFormItem>

      <ElFormItem>
        <CommonButton
          :loading="loading"
          class="login-button w-full justify-center"
          type="primary"
          @click.prevent="submitLogin"
        >
          Submit
        </CommonButton>
      </ElFormItem>
    </ElForm>
  </LayoutsFormSection>
</template>

<script setup lang="ts">
import { ElForm, ElFormItem, ElInput } from "element-plus"
import type { FormInstance, FormRules } from "element-plus"
import { useUserStore } from "~/stores/user"
import { useDialog } from "~/composables/useDialog"
import { useGoogle } from "~/stores/google"

const { getValue } = useStorage()
const google = useGoogle()
const { checkGoogleCode } = useCheck()

const rules = computed<FormRules>(() => ({
  googleCode: [{ required: true, validator: checkGoogleCode, trigger: "blur" }],
}))
const user: any = useUserStore()

type RuleFormType = {
  googleCode: string
}
const loading = shallowRef<boolean>(false)
const ruleFormRef = ref<FormInstance>()
const ruleForm: RuleFormType = reactive({
  googleCode: "",
})

const submitLogin = () => {
  ruleFormRef?.value?.validate(async (v: boolean) => {
    if (v) {
      const code = await google.BIND_GOOGLE_CODE({
        googleCode: +ruleForm.googleCode,
        secret: getValue("secret"),
      })
      if (code === 0) {
        ruleFormRef?.value?.clearValidate()
        useNotify({
          title: "Successfully",
          message: "Successfully",
          type: "success",
          duration: 1000,
          position: "top-left",
        })
        user.GET_USER_PROFILE()
        useDialog().closeDialog()
      }
    }
  })
}
</script>

<style lang="scss" scoped></style>

<template>
  <div class="gaf">
    <div class="flex items-center justify-between">
      <div class="flex items-center text-14px">
        <div class="text-35px"><CommonAsset name="icons-google" /></div>
        <span>Link with Google</span>
      </div>
      <div
        class="flex items-center cursor-pointer"
        :class="{ 'pointer-events-none': providerInfo.googleLoginEmail }"
        @click="bindingUrl(1)"
      >
        <span>{{ providerInfo.googleLoginEmail || "Link" }} </span>
        <div
          v-if="!providerInfo.googleLoginEmail"
          class="flex transform rotate-180 ml-5px"
        >
          <CommonAsset name="icons-back" class="w-20px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGoogle } from "~/stores/google"
import { useUserStore } from "~/stores/user"
const { setValue, getValue } = useStorage()

const google = useGoogle()
const userStore = useUserStore()

await google.GET_PROVIDER_INFO()
const providerInfo = computed(() => google.providerInfo)

function bindingUrl(thirdType: number | string) {
  setValue("thirdType", thirdType as string)
  thirdAccessLink()
}
async function thirdAccessLink() {
  const res = await userStore.PROVIDER_BINDING({
    thirdType: getValue("thirdType") ?? 1,
    device: useDeviceSymbol(),
  })
  if (res.code === 0) {
    const url = new URL(res.data)
    const redirectURL = new URL(window.location.href)
    const redirect_uri = `${redirectURL.origin}`
    url.searchParams.set("redirect_uri", redirect_uri)
    const finalHref = url.href
    navigateTo(finalHref, {
      external: true,
      replace: true,
    })
  }
}
</script>

<style scoped></style>

<template>
  <CustomDialog
    v-show="typeInfo.component"
    v-model="showVisible"
    :show-footer="false"
    :title="typeInfo.title"
    :show-close-button="typeInfo.showCloseButton"
    :before-close="beforeClose"
  >
    <template v-if="dialogContainerList.includes(dialogState.type)" #append>
      <ViewsUserDialogWithBanner>
        <component :is="typeInfo.component" :before-close="beforeClose" />
      </ViewsUserDialogWithBanner>
    </template>
    <component :is="typeInfo.component" :before-close="beforeClose" />
  </CustomDialog>
</template>

<script setup lang="ts">
import type { StoreTypes } from "~/types/store"
import { dialogState, useDialog, lockNx } from "~/composables/useDialog"
const { t } = useLang()

const { setValue } = useStorage()
type ComponentType = {
  title: string
  key: DialogTypeKey
  component: any
  confirmText?: string
  showCloseButton?: boolean
}
const dialogContainerList: StoreTypes.User.State["dialogType"][] = [
  "signIn",
  "signUp",
  "PwdSign",
]

const Login = resolveComponent("ViewsUserLogin")
const ForgetPassword = resolveComponent("ViewsUserForgetPassword")
const CreateCard = resolveComponent("ViewsUserCreateCard")
const ChangePassword = resolveComponent("ViewsUserChangePassword")
const ChangeMobile = resolveComponent("ViewsUserChangeMobile")
const VerifyAccountStep = resolveComponent("ViewsUserVerifyAccountStep")
const Authenticator = resolveComponent("ViewsUserAuthenticator")
const AuthGoogleCode = resolveComponent("ViewsUserAuthGoogleCode")
const KycForm = resolveComponent("ViewsUserKycMainForm")
const ProviderAccount = resolveComponent("ViewsUserProviderAccount")
const PwdSign = resolveComponent("ViewsUserSignInWithPwd")
const ConfirmAge = resolveComponent("ViewsUserConfirmAge")
const VerifyPhoneNumber = resolveComponent("ViewsUserVerifyPhoneNumber")
const BindFiatWallet = resolveComponent("ViewsUserBindFiatWallet")

const showVisible = computed(() => {
  return !!dialogState.type
})

const componentTypeList = [
  {
    title: "Sign In",
    component: Login,
    key: "signIn",
    confirmText: "Sign In",
  },
  {
    title: "Forgot Password",
    component: ForgetPassword,
    key: "forgotPassword",
  },
  {
    title: "Add Address",
    component: CreateCard,
    key: "createCard",
  },
  {
    title: "Change Password",
    component: ForgetPassword,
    key: "changePassword",
  },
  {
    title: "Set Password",
    component: ChangePassword,
    key: "setPassword",
  },
  {
    title: "Mobile",
    component: ChangeMobile,
    key: "changeMobile",
  },
  {
    title: "Verify Account",
    component: VerifyAccountStep,
    key: "verifyAccountStep",
  },
  {
    title: "Google Authenticator",
    component: Authenticator,
    key: "authenticator",
  },
  {
    title: "Google Authenticator",
    component: AuthGoogleCode,
    key: "authGoogleCode",
  },
  {
    title: t("mine.profile.kyc"),
    component: KycForm,
    key: "kycSetting",
  },
  {
    title: "Account Links",
    component: ProviderAccount,
    key: "providerAccount",
  },
  {
    title: "Login",
    component: PwdSign,
    key: "PwdSign",
  },
  {
    title: "",
    component: ConfirmAge,
    key: "confirmAge",
  },
  {
    title: "Verify phone number",
    component: VerifyPhoneNumber,
    key: "VerifyPhoneNumber",
  },
  {
    title: "Bind fiat wallet",
    component: BindFiatWallet,
    key: "BindFiatWallet",
    showCloseButton: false,
  },
] as ComponentType[]

// 组件详情
const typeInfo = computed(
  () =>
    componentTypeList?.find(
      (item: ComponentType) => item.key === dialogState.type?.split(":")[0]
    ) ?? {}
) as unknown as Ref<ComponentType>

// 关闭之前
const beforeClose = () => {
  if (dialogState.type === "confirmAge") {
    setValue("dialogConfirm", "1")
  }
  useDialog().closeDialog()
}
</script>

<style scoped></style>

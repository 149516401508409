import { useDeviceSymbol } from "~/composables/useDeviceSymbol"

export const useHideScrollbar = () => {
  const deviceSymbol = useDeviceSymbol()

  return computed(() => {
    switch (deviceSymbol) {
      case "d":
        return ""
      case "m":
        return "scrollbar-hide"
    }
  })
}

<template>
  <LayoutsFormSection class="flex flex-col mt-0">
    <UserLoginOrRegisterTitle />
    <ElForm
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm flex flex-col flex-1"
      status-icon
    >
      <FormMobileItem
        v-model="ruleForm.mobile"
        :areacode="ruleForm.areaCode"
        :formatter-fn="mobileFormatters"
        @area-change="onAreaChange"
        class="white"
        v-if="phoneVisible"
      />
      <FormVerifyCode
        ref="formVerifyCode"
        v-model="ruleForm.verifyCode"
        class="white"
        @init="handleInitVerifyCodeElement"
        v-if="psdVisible"
      >
        <FormVerifyButton
          ref="child"
          smstype="REG_LOGIN"
          :areacode="ruleForm.areaCode"
          :value="ruleForm.mobile"
          @SmsCodeSubmit="handleFocusInput"
        />
      </FormVerifyCode>
      <ElFormItem prop="promoCode" label="Invitation Code" class="white">
        <ElInput
          v-model="ruleForm.promoCode"
          :placeholder="t('mine.InvitationCode')"
          :disabled="isHavePromoCode"
        />
      </ElFormItem>
      <FormTermsAndConditionCheckItem
        v-model="checked"
        :is-check-high-light="isCheckLight"
      />

      <ElFormItem>
        <CommonButton
          :loading="loading"
          class="login-button w-full justify-center"
          type="primary"
          @click.prevent="submitLogin"
          >Sign Up
        </CommonButton>
      </ElFormItem>
      <p class="text-14px text-center">
        Already have account？
        <span
          class="is-primary font-bold cursor-pointer"
          @click="toOpen('PwdSign')"
          >Login Here</span
        >
      </p>
      <ViewsUserLoginAnnouncement v-model:promo-code="ruleForm.promoCode" />
    </ElForm>
  </LayoutsFormSection>
</template>

<script setup lang="ts">
import { ElForm, ElFormItem, ElInput } from "element-plus"
import type { FormInstance, FormRules } from "element-plus"
import { computed, onMounted } from "vue"
import { useUserStore } from "~/stores/user"
import { useAppStore } from "~/stores/app"
import { useDialog } from "~/composables/useDialog"
import { usePromoCodeManager } from "~/composables/usePromoCodeManager"
import { EnumKycStatus } from "~/types/enum"

const promoCodeManager = usePromoCodeManager()
const verifyInput = ref<HTMLElement>()
const isHavePromoCode = ref<boolean>(false)
const verify = ref()
const { checkPromoCode } = useCheck()
const rules = computed<FormRules>(() => ({
  promoCode: [{ validator: checkPromoCode, trigger: "blur" }],
}))
const { t } = useLang()
const user = useUserStore()
const app = useAppStore()
type RuleFormType = {
  areaCode: string
  mobile: string
  promoCode: number | string
  verifyCode: number | string
}
const { VERIFY_CODE } = useConfig()
const child = ref<{ startSendSmsCode: (data: any) => number }>()
const checked = shallowRef<boolean>(true) // terms 同意政策
const isCheckLight = ref(false)
const loading = shallowRef(false)
const ruleFormRef = ref<FormInstance>()
const ruleForm: RuleFormType = reactive({
  areaCode: "+886",
  verifyCode: "",
  mobile: "",
  promoCode: "",
})
const phoneVisible = computed(()=>{
  return app.registerItemData?.find((item:any) => item.validKey === "phone")?.showEnabled
})
const psdVisible = computed(()=>{
  return app.registerItemData?.find((item:any) => item.validKey === "password")?.showEnabled
})

const submitLogin = () => {
  if (!checked.value) {
    useNotify({
      title: "Warning",
      message: "You have not selected Terms & Condition",
      type: "warning",
      position: "top-left",
    })
    isCheckLight.value = true
    return
  }

  ruleFormRef?.value?.validate(async (v: boolean) => {
    if (v) {
      loading.value = true
      // 電話號碼統一去掉開頭0再傳,使用者可以輸入或不輸入0
      const { mobile, ...formData } = ruleForm
      let mobileNumber = mobile.toString()
      if (mobileNumber.charAt(0) === "0")
        mobileNumber = mobileNumber.slice(1, mobileNumber.length)
      try {
        const { code } = await user.REGISTER({
          mobile: mobileNumber,
          ...formData,
        })
        if (code === 0) {
          // useDialog().closeDialog()
          await user.GET_USER_KYC()
          await app.GET_KYC_DIALOG()
          if (!(await openKycAfterAuth1())) {
            useDialog().closeDialog()
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        loading.value = false
      }
    }
  })
}
const openKycAfterAuth1 = async () => {
  const userStore = useUserStore()
  await userStore.GET_USER_KYC()
  const kycStatus = useKycStatus()
  if (
    ![
      //
      EnumKycStatus.CSR_APPROVED,
      EnumKycStatus.BLOCKED,
      EnumKycStatus.COMPLETED,
      EnumKycStatus.REVERIFY,
      EnumKycStatus.CERTIFY,
    ].includes(kycStatus.value)
  ) {
    const dialog = useDialog()
    if (app.kycValidDialogVisible.registKycValidDatetime == 1) {
      dialog.openDialog("kycSetting")
      return true
    }
  } else {
    return false
  }
}
const confirmButtonDisabled = computed(() => {
  const { verifyCode, mobile } = ruleForm
  return !verifyCode || !(verifyCode.toString().length === 6) || !mobile
})
const mobileFormatters = (val: string) => {
  ruleForm.mobile = formatters.mobile(val.toString())
}
const request = async (data: any) => {
  if (VERIFY_CODE.value === "1") verify.value.show()
  if (child.value) {
    const code = await child.value.startSendSmsCode(data)
    return code || 0
  }
}

const toOpen = (type: DialogTypeKey) => {
  useDialog().openDialog(type)
}

const handleInitVerifyCodeElement = (el: HTMLElement) => {
  verifyInput.value = el
}

const handleFocusInput = () => {
  if (verifyInput.value) {
    verifyInput.value.focus()
  }
}

const onAreaChange = (value: string) => {
  ruleForm.areaCode = value
}
</script>

<style lang="scss" scoped>
.is-primary {
  color: #1999ee;
  opacity: 1;
}
.title {
  margin: -20px 0px 7px 0px;
  @media (max-width: 700px) {
    margin-bottom: -2px;
  }
}
</style>

<script setup lang="ts">
import { EnumKycStatus } from "~/types/enum"
import { useLang, useKycForm, useKycStatus } from "#imports"

const { form } = useKycForm()
const kycStatus = useKycStatus()
const { t } = useLang()
</script>

<template>
  <p
    v-if="kycStatus === EnumKycStatus.CSR_REJECTED"
    class="text-left kyc-error text-14px is-fail font-600"
  >
    {{ t("mine.profile.refuseReason") }} : {{ form.remark }}
  </p>
</template>

<template>
  <LayoutsFormSection class="flex flex-col mt-0">
    <ul class="flex items-center justify-around mt-20px mb-30px">
      <li>
        <button
          class="text-14px font-bold opacity-50 py-5px hover:opacity-80"
          :class="{
            '!opacity-100 border-b-1px border-b-#FFFFFF cursor-default':
              activeType === 0,
          }"
          @click="activeType = 0"
        >
          {{ t('kyc.username') }}
        </button>
      </li>
      <li>
        <button
          class="text-14px font-bold opacity-50 py-5px hover:opacity-80"
          :class="{
            '!opacity-100 border-b-1px border-b-#FFFFFF cursor-default':
              activeType === 1,
          }"
          @click="activeType = 1"
        >
        {{ t('sign.phone') }}
        </button>
      </li>
    </ul>

    <div v-if="activeType === 0" class="mb-32px">
      <ElForm
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm flex flex-col flex-1"
        status-icon
      >
        <ElFormItem prop="username" :label="t('kyc.username')" class="white">
          <ElInput v-model="ruleForm.username" :placeholder="t('kyc.username')" />
        </ElFormItem>
        <FormPassWordItem
          v-model="ruleForm.password"
          validate-key="checkPasswordSignIn"
          :placeholder="t('sign.enterPsw')"
          class="white"
        />
        <div class="flex justify-end">
          <p
            class="text-14px ml-5px is-primary cursor-pointer"
            @click="toOpen('forgotPassword')"
          >
            {{ t('sign.forgot') }}
          </p>
        </div>
      </ElForm>
    </div>
    <div v-if="activeType === 1" class="mb-44px">
      <ElForm
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm flex flex-col flex-1"
        status-icon
      >
        <FormMobileItem
          v-model="ruleForm.mobile"
          :areacode="ruleForm.areaCode"
          :formatter-fn="mobileFormatters"
          @area-change="onAreaChange"
          class="white"
        />

        <FormVerifyCode
          ref="formVerifyCode"
          v-model="ruleForm.verifyCode"
          class="white"
          @init="handleInitVerifyCodeElement"
        >
          <FormVerifyButton
            ref="child"
            smstype="REG_LOGIN"
            :areacode="ruleForm.areaCode"
            :value="ruleForm.mobile"
            @SmsCodeSubmit="handleFocusInput"
          />
        </FormVerifyCode>
      </ElForm>
    </div>
    <ElFormItem>
      <CommonButton
        :disabled="confirmButtonDisabled || loading"
        :loading="loading"
        class="login-button w-full justify-center"
        type="primary"
        @click.prevent="submitLogin"
        >{{ t('header.login') }}
      </CommonButton>
    </ElFormItem>
    <p class="text-14px text-center mt-4">
      {{ t('sign.yet') }}
      <span
        class="is-primary font-bold cursor-pointer"
        @click="toOpen('signIn')"
        >{{ t('sign.signUpNow') }}</span
      >
    </p>
    <ViewsUserLoginAnnouncement />
  </LayoutsFormSection>
</template>

<script setup lang="ts">
import { ElForm, ElFormItem, ElInput } from "element-plus"
import type { FormInstance, FormRules } from "element-plus"
import { ref, computed } from "vue"
import { useUserStore } from "~/stores/user"
import { useAppStore } from "~/stores/app"
import { useDialog } from "~/composables/useDialog"
import * as validate from "~/utils/validate"

const activeType = ref<number>(0)
const { checkUsernameOrMobile, checkPasswordSignIn, checkUsername } = useCheck()
const rules = computed<FormRules>(() => {
  switch (activeType.value) {
    case 0:
      return {
        username: [
          {
            required: true,
            validator: checkUsername,
            trigger: "change",
          },
        ],
        password: [
          {
            //
            required: true,
            validator: checkPasswordSignIn,
            trigger: "change",
          },
        ],
      }
    case 1:
      return {}
    default:
      return {}
  }
})
const { t } = useLang()
const user = useUserStore()
const appStore = useAppStore()
type RuleFormType = {
  username: string
  password: string
  areaCode: string
  mobile: string
  verifyCode: string
}
const loading = shallowRef(false)
const ruleFormRef = ref<FormInstance>()
const verifyInput = ref<HTMLElement>()
const ruleForm: RuleFormType = reactive({
  username: "",
  password: "",
  areaCode: "+886",
  mobile: "",
  verifyCode: "",
})
const confirmButtonDisabled = computed(() => {
  const { username, password, verifyCode, mobile } = ruleForm

  switch (activeType.value) {
    case 0:
      return !username || !password
    case 1:
      return !verifyCode || !(verifyCode.toString().length === 6) || !mobile
    default:
      return false
  }
})

const isQuickLoginState = computed(() => appStore.isQuickLogin)

const submitLogin = () => {
  switch (activeType.value) {
    case 0:
      ruleFormRef?.value?.validate(async (v: boolean) => {
        if (v) {
          const { username, password } = ruleForm
          let userNameValue = username

          if (validate.isMobile(userNameValue)) {
            if (userNameValue.charAt(0) === "0")
              userNameValue = userNameValue.slice(1, userNameValue.length)
          }
          if (!validate.isPasswordOld(password)) {
            useNotify({
              title: "Error",
              type: "error",
              message: "Invalid password. Please try again!",
              position: "top-left",
            })
            return
          }

          try {
            loading.value = true

            const { code } = await user.LOGIN({
              username: userNameValue,
              verifyPhoneNumber: !isQuickLoginState.value,
              password,
            })
            if (code === 0) {
              ruleFormRef?.value?.clearValidate()
              ruleForm.username = ""
              ruleForm.password = ""
              if (user.info?.mobile !== "" && !isQuickLoginState.value) {
                useDialog().openDialog("VerifyPhoneNumber", "VerifyPhoneNumber")
              } else {
                user.CHECK_HASH_STATUS()
                // useDialog().closeDialog()
                if (!(await openKycAfterAuth())) {
                  useDialog().closeDialog()
                }
              }
            }
          } catch (e) {
            console.log(e)
          } finally {
            loading.value = false
          }
        }
      })
      break
    case 1:
      ruleFormRef?.value?.validate(async (v: boolean) => {
        if (v) {
          // 電話號碼統一去掉開頭0再傳,使用者可以輸入或不輸入0
          const { mobile, areaCode, verifyCode } = ruleForm
          let mobileNumber = mobile.toString()
          if (mobileNumber.charAt(0) === "0")
            mobileNumber = mobileNumber.slice(1, mobileNumber.length)

          try {
            loading.value = true

            const { code } = await user.MOBILE_LOGIN({
              mobile: mobileNumber,
              areaCode,
              verifyCode,
            })
            if (code === 0) {
              // useDialog().closeDialog()
              if (!(await openKycAfterAuth())) {
                useDialog().closeDialog()
              }
            }
          } catch (e) {
            console.log(e)
          } finally {
            loading.value = false
          }
        }
      })
      break
    default:
      break
  }
}

const mobileFormatters = (val: string) => {
  ruleForm.mobile = formatters.mobile(val.toString())
}

const toOpen = (type: DialogTypeKey) => {
  useDialog().openDialog(type)
}

const handleInitVerifyCodeElement = (el: HTMLElement) => {
  verifyInput.value = el
}

const handleFocusInput = () => {
  if (verifyInput.value) {
    verifyInput.value.focus()
  }
}

const onAreaChange = (value: string) => {
  ruleForm.areaCode = value
}
</script>

<style lang="scss" scoped>
.is-primary {
  color: #1999ee;
  opacity: 1;
}

.title {
  margin: -20px 0px 17px 0px;
  @media (max-width: 700px) {
    margin-top: 5px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  box-shadow: none !important;
}
</style>

export const usePromoCodeManager = () => {
  const savePromoCode = (promoCode: string) => {
    localStorage.setItem("promoCode", promoCode)
  }

  const saveManualInputPromoCode = (promoCode: string) => {
    localStorage.setItem("manualInputPromoCode", promoCode)
  }

  const getPromoCode = (localName?: string) => {
    return localStorage.getItem(localName ?? "promoCode")
  }

  const clearPromoCode = (localName?: string) => {
    return localStorage.removeItem(localName ?? "promoCode")
  }

  return {
    savePromoCode,
    saveManualInputPromoCode,
    getPromoCode,
    clearPromoCode,
  }
}

<template>
  <LayoutsFormSection>
    <ElForm
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      status-icon
    >
      <ElFormItem v-if="passwordHash" prop="oldPassword" label="Old Password">
        <ElInput
          v-model="ruleForm.oldPassword"
          type="password"
          show-password
          placeholder="Enter Old Password"
        />
      </ElFormItem>
      <FormPassWordItem
        v-model="ruleForm.password"
        validate-key="checkPassword"
        placeholder="Enter the New Password"
        autocomplete="new-password"
      />
      <FormComfirmPassWordItem
        v-model="ruleForm.confirmPassword"
        :oldpassword="ruleForm.password"
        placeholder="Confirm the New Password"
      />
      <ElFormItem>
        <CommonButton
          :loading="loading"
          class="login-button w-full justify-center"
          type="primary"
          @click.prevent="submitLogin"
        >
          {{ hasStep ? "Next" : "Confirm" }}
        </CommonButton>
      </ElFormItem>
    </ElForm>
  </LayoutsFormSection>
</template>

<script setup lang="ts">
import { ElForm, ElFormItem, ElInput } from "element-plus"
import type { FormInstance, FormRules } from "element-plus"
import { computed } from "vue"
import { useUserStore } from "~/stores/user"
import { useDialog } from "~/composables/useDialog"

const props = defineProps<{ hasStep: boolean }>()
const hasStep = computed(() => props.hasStep)
const dialog = useDialog()

const { checkPasswordOld } = useCheck()
const rules = computed<FormRules>(() => ({
  oldPassword: [
    { required: true, validator: checkPasswordOld, trigger: "blur" },
  ],
}))
const user = useUserStore()
// 是否设置了密码
const passwordHash = computed(() => user.info?.passwordHash)
type RuleFormType = {
  password: string
  oldPassword: string
  confirmPassword: string
}
const loading = shallowRef(false)
const ruleFormRef = ref<FormInstance>()
const ruleForm: RuleFormType = reactive({
  password: "",
  oldPassword: "",
  confirmPassword: "",
})

const submitLogin = () => {
  ruleFormRef?.value?.validate(async (v: boolean) => {
    if (v) {
      loading.value = true
      try {
        if (passwordHash.value) await resetPassword()
        else await setPassword()
      } catch (e) {
        console.log(e)
      }
      loading.value = false
    }
  })
}

// 重设密码
const resetPassword = async () => {
  const response = await user.RESET_PASSWORD({
    ...ruleForm,
    category: 1,
  })

  if (response.code !== 0) {
    return
  }

  ruleFormRef?.value?.clearValidate()
  ruleForm.password = ""
  useNotify({
    title: "Successfully",
    message: "Reset Successfully,please login again",
    type: "success",
    duration: 1000,
    position: "top-left",
  })
  try {
    await dialog.closeDialog()
    await user.LOGOUT()
    await dialog.openDialog("PwdSign")
  } catch (e) {}
}
// 设置密码
const setPassword = async () => {
  const { code } = await user.SET_PASSWORD({
    password: ruleForm.password,
  })
  if (code === 0) {
    ruleFormRef?.value?.clearValidate()
    ruleForm.password = ""
    useNotify({
      title: "Successfully",
      message: "Set Successfully",
      type: "success",
      duration: 1000,
      position: "top-left",
    })
    if (hasStep.value) dialog.openDialog("verifyAccountStep:3")
    else dialog.closeDialog()
    user.GET_USER_PROFILE()
  }
}
</script>

<style lang="scss" scoped></style>

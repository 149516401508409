<script setup lang="tsx">
import { defineExpose } from "vue"

defineExpose({
  Title: () => <div>Default404</div>,
})
</script>
<template>
  <div class="Default404">Default404</div>
</template>

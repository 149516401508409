<template>
  <div>
    <CommonAsset class="max-h-30px m-auto" :src="PLAT_LOGO" alt="plat-logo" />
    <p
      class="text-20px leading-24px font-500 mt-50px <sm:mt-33px <sm:text-12px <sm:leading-16px @sm:mt-40px @sm:text-14px @sm:leading-18px @md:mt-45px @md:text-16px @md:leading-20px"
    >
      Please ensure that you are 21 years of age or older to access this site.
    </p>
    <CommonButton class="w-full mt-40px" @click="beforeClose">
      <span>I am 21 years old</span>
    </CommonButton>
    <div class="cursor-pointer" @click="beforeClose">
      <CommonButton disabled class="w-full mt-20px">
        <span>Exit</span>
      </CommonButton>
    </div>
    <ViewsUserPagorCertify
      class="mt-50px <sm:mt-23px @sm:mt-30px @md:mt-35px @lg:mt-40px"
    />
  </div>
</template>

<script setup lang="ts">
const { PLAT_LOGO } = useConfig()
defineProps<{
  beforeClose: () => void
}>()
</script>

<style scoped></style>

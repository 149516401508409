<template>
  <ElFormItem class="!mb-32px">
    <div
      class="flex color-white items-center text-12px terms-text"
    >
      <LazyCommonAsset
        v-show="checked"
        class="w-[14px] flex-shrink-0 ml-4px"
        name="icons-selected"
      />
      <span
        v-show="!checked"
        class="checked-circle ml-4px block w-14px h-14px border-1px border-gray-400 rounded-1/2 flex-shrink-0"
        :class="{
          '!border-[#fff] !border-2px opacity-100': props.isCheckHighLight,
          highlight: props.isCheckHighLight,
        }"
      />
      <p class="ml-5px">
        I agree and understand the
        <span class="is-primary cursor-pointer" @click="openTerms">Terms and Conditions</span>
        and
        <span class="is-primary cursor-pointer" @click="openResGame">Responsible Gambling</span>
        and Privacy Policy, Including Cookie use.
      </p>
    </div>
  </ElFormItem>
  <Teleport to="body">
    <el-dialog
      v-model="dialogVisible"
      width="50%"
      :show-close="!!checked"
      class="terms-agreement-dialog text-[12px]"
      :close-on-press-escape="false"
      :close-on-click-modal="!!checked"
      :modal="true"
    >
      <template #header>
        <div class="text-center w-full text-[1.5em] font-600">
          Terms and Conditions
        </div>
      </template>
      <div class="dialog-content">
        <div class="term-content">
          <ScrollArea>
            <FormTermsAndConditonContent
              :is-title-show="false"
              @on-scroll-bottom="onTermsContentScroll"
            />
          </ScrollArea>
        </div>
        <div
          class="terms-note mt-10px font-700 flex flex-row flex-nowrap space-x-[5px] items-center"
          :class="{
            isScrolledBottom: isScrolledBottom,
            'text-red-600': !isScrolledBottom,
            'text-green-400': isScrolledBottom,
          }"
        >
          <div
            class="terms-warning-icon inline-flex justify-center align-middle rounded-[100px] pl-[20px] pt-[20px] w-0 h-0"
            :class="{
              'bg-red-600': !isScrolledBottom,
              'bg-green-400': isScrolledBottom,
            }"
          />
          <div class="inline-block <sm:text-12px">
            You must read all terms and conditions carefully before clicking "I
            Agree".
          </div>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <CommonButton
            class="w-1/3 <md:w-full iagree-btn"
            type="primary"
            :disabled="!isScrolledBottom || checked"
            @click.prevent="onAgree"
          >
            I Agree
          </CommonButton>
          <CommonButton
            class="common-button-grey w-1/3 <md:w-full"
            :disabled="checked"
            @click.prevent="onDisagree"
            >Disagree</CommonButton
          >
        </div>
      </template>
    </el-dialog>
  </Teleport>
</template>
<script setup lang="ts">
import ScrollArea from "~/components/common/ScrollArea.vue"

const props = withDefaults(
  defineProps<{
    modelValue: boolean
    isCheckHighLight: Boolean
  }>(),
  {
    isCheckHighLight() {
      return false
    },
  }
)

const emit = defineEmits(["update:modelValue", "change"])

const checked = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit("update:modelValue", value)
    emit("change", value)
  },
})

const dialogVisible = ref(false)
const isScrolledBottom = ref(false)

const onClick = () => {
  if (unref(checked)) return
  dialogVisible.value = true
}
const openTerms = () => {
  window.open(`${location.origin}/terms`)
}
const openResGame = () => {
  window.open(`${location.origin}/responsible-gambling`)
}
const onAgree = () => {
  checked.value = true
  dialogVisible.value = false
}
const onDisagree = () => {
  checked.value = false
  dialogVisible.value = false
}

const onTermsContentScroll = (visible: boolean) => {
  if (visible) {
    isScrolledBottom.value = true
  }
}
</script>

<style lang="scss" scoped>
.terms-text {
  color: #b3bad1;
  transition: none;
  opacity: 0.6;
  -webkit-tap-highlight-color: transparent;
}

.color-white {
  color: #fff;
  opacity: 1;
}
.checked-circle {
  &.highlight {
    animation: checkHighlight infinite 1s;
  }
}
.terms-note {
  transition: none;
  .terms-warning-icon {
    position: relative;
    &::after {
      content: "!";
      position: absolute;
      color: black;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
  }
  &.isScrolledBottom > .terms-warning-icon::after {
    content: "v";
  }
}

@keyframes checkHighlight {
  0% {
    box-shadow: 0 0 0px yellow;
  }
  50% {
    box-shadow: 0 0 3px 2px yellow;
  }
  100% {
    box-shadow: 0 0 0px yellow;
  }
}

@media screen and (max-width: 767px) {
  .iagree-btn {
    margin-bottom: 10px;
  }
}
</style>
<style lang="scss">
.el-overlay-dialog {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}
.terms-agreement-dialog {
  height: 80vh;
  margin: 0 auto !important;
  @apply <sm:text-[10px] <sm:w-9/10 <md:text-[12px] <md:w-16/20 <lg:text-[12px] <lg:w-6/10 text-[16px] w-6/10 rounded-[0.5em] max-w-1000px !important;
  background: #1e202b !important;
  .el-dialog__body {
    height: calc(100% - 168px);
    @apply py-0 mb-10px !important;
  }
  .dialog-content {
    @apply h-[100%] p-0 m-0 rounded-lg !important;
    &::-webkit-scrollbar {
      width: 10px;
    }

    .term-content {
      height: calc(100% - 24px);
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: darkgrey;
      min-height: 3em;
    }
  }
  .dialog-footer {
    @apply flex justify-center justify-evenly <md:flex-col <md:flex-shrink-0 <md:w-full !important;
  }
}
</style>

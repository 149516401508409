<template>
  <section
    class="provider-login flex items-center justify-between flex-1 mt-20px"
  >
    <div class="flex flex-col items-center">
      <div class="show-or flex items-center w-full">
        <div class="h-1px w-full line-or"></div>
        <span
          class="mx-8px flex-shrink-0 text-12px font-normal <sm:mx-6px font-400"
        >
        {{ t('sign.orWith') }}
        </span>
        <div class="h-1px w-full line-or"></div>
      </div>
      <div
        class="grid-item text-35px w-90px h-44px cursor-pointer flex items-center justify-center mt-20px <sm:mt-15px"
        @click="login(1)"
      >
        <CommonAsset name="icons-google" />
      </div>
      <div
        class="text-12px leading-14px text-center <sm:mt-10px mt-22px @sm:mt-13px @md:mt-13px @lg:mt-17px"
      >
        <span class="terms-text">
          {{ t('sign.bylogin') }}
        </span>
        <span class="is-primary cursor-pointer" @click="openTerm"
          >{{ t('kyc.term') }}</span
        >
      </div>
    </div>
    <ViewsUserPagorCertify class="mt-20px" />
  </section>
</template>

<script setup lang="ts">
import { useUserStore } from "~/stores/user"
import { usePromoCodeManager } from "~/composables/usePromoCodeManager"

const { t } = useLang()
// define promoCode v-model
const props = defineProps({
  promoCode: {
    type: String,
    default: "",
  },
})

const emit = defineEmits(["update:promoCode"])

const promoCodeModel = computed({
  get() {
    return props.promoCode
  },
  set(value) {
    emit("update:promoCode", value)
  },
})

const userStore = useUserStore()
const promoCodeManager = usePromoCodeManager()
const data = reactive<{ thirdType: number | string; device: string }>({
  thirdType: 1,
  device: "d",
})

const { VERIFY_CODE } = useConfig()

const verify = ref()
const { setValue } = useStorage()

function login(thirdType: number | string) {
  data.thirdType = thirdType
  setValue("thirdType", thirdType as string)
  if (VERIFY_CODE.value === "1") verify.value.show()
  else thirdLogin({})
}
async function thirdLogin(para: {}) {
  const res: any = await userStore.PROVIDER_LOGIN({
    ...para,
    ...data,
  })
  if (res.code === 0) {
    const promoCode = promoCodeModel.value
    if (promoCode !== "") {
      promoCodeManager.saveManualInputPromoCode(promoCode)
    }
    location.href = res.data
  }
}
const openTerm = () => {
  window.open(`${location.origin}/terms`)
}
</script>

<style lang="scss" scoped>
.provider-login {
  flex-direction: column;
  .line-or {
    background: var(--v-form-line-bg);
  }
  .grid-item {
    background: url("~/assets/images/common/button.png") no-repeat center center;
    background-size: 100% 100%;
  }
  .terms-text {
    color: #b3bad1;
  }
}
</style>

<template>
  <Teleport v-if="ifShowPopup" to="body">
    <div
      class="mask fixed z-11000 left-0 top-0 w-100vw h-100vh flex items-center justify-center"
    >
      <div class="relative content grid gap-24px">
        <button
          class="absolute right-[-9px] lg:top-[-12px] top-[-8px] z-100"
          @click="visible = false"
        >
          <CommonAsset name="images-activity-christmas-promotion-close-btn" />
        </button>
        <div class="tab-mask"></div>
        <ul
          class="flex flex-col gap-35px <lg:mb-12px lg:overflow-y-scroll lg:h-full"
        >
          <li v-for="(item, index) in activityList" :key="index">
            <Button
              style="--v-navbar-bg: ''"
              :is-active="index === currentIndex"
              class="min-w-172px text-14px font-500 leading-45px"
              @click.stop="currentIndex = index"
            >
              {{ PromotionName[item.activityType] }}
            </Button>
          </li>
        </ul>
        <div class="img">
          <CommonAsset
            @click="openUrl"
            class="w-full h-full lg:h-395px"
            :class="{ 'cursor-pointer': !!activityList[currentIndex]?.href }"
            :src="
              activityList[currentIndex][device === 'm' ? 'imageH5' : 'imagePc']
            "
          />
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script setup lang="ts">
import HttpInstance from "~/request/server"
import Button from "~/components/common/Button.vue"
import { ActivityPopupItem, PromotionName } from "~/components/promotion/type"
import { useChristmasStore } from "~/stores/christmas"
import { useActivityStore } from "~/stores/activity"

const christmasStore = useChristmasStore()
const device = useDeviceSymbol()
const activityStore = useActivityStore()
const visible = ref(true)
const activityList = ref<ActivityPopupItem[]>([])
const currentIndex = ref(0)
const ifShowPopup = computed(
  () => visible.value && activityList.value?.length > 0
)

const fetchActivityList = async () => {
  try {
    const { data, headers } = await HttpInstance.post("getPromoPopupList")
    const testflight = headers.get("testflight")
    if (testflight) {
      activityStore.SET_TESTFLIGHT(JSON.parse(testflight))
    }
    console.log(data)
    activityList.value = data
  } catch (e) {
    console.log(e)
  }
}
const openUrl = () => {
  const href = unref(activityList)[currentIndex.value]?.href
  if (href) {
    window.open(href)
  }
}

onMounted(fetchActivityList)
</script>

<style scoped lang="scss">
.mask {
  backdrop-filter: blur(5px);
}

.tab-mask {
  display: none;
}

.content {
  padding: 40px;
  grid-template-columns: auto 1fr;
  align-items: center;
  width: 1071px;
  height: 529px;
  border-radius: 20px;
  background: no-repeat -15px -15px/110% 110%
      url("~/assets/images/promotions/promotions.png"),
    rgba(38, 54, 66, 1);
}

.common-button {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .tab-mask {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    box-shadow: -20px 0 10px -20px rgba(0, 0, 0, 0.8) inset,
      20px 0 10px -20px rgba(0, 0, 0, 0.8) inset;
    z-index: 2;
    border-radius: 20px;
  }
  .content {
    padding: 24px 0;
    width: 347px;
    min-height: 477px;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    align-items: initial;
    gap: 0;
    background: no-repeat top center/cover
        url("~/assets/images/promotions/App_bg.png"),
      rgba(38, 54, 66, 1);

    .img {
      padding: 0 30px;
    }

    ul {
      gap: 8px;
      overflow-x: scroll;
      flex-direction: row;
      padding: 0 30px;

      li {
        button {
          padding: 0 16px;
          white-space: nowrap;
          min-width: auto;
          line-height: 38px;
        }
      }
    }
  }
}
</style>

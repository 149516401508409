<!-- eslint-disable vue/no-v-html -->
<template>
  <ul class="pb-[38px] pt-[24px]">
    <li
      v-for="(item, index) in stepList"
      :key="item.title"
      class="relative py-[16px] pr-[24px]"
    >
      <div class="text-left text-[14px] leading-[25px] text-[#fff]">
        <p class="mb-[16px] font-semibold <md:text-[14px] <md:mb-[8px]">
          {{ t('kyc.step01')+(index + 1)+t('kyc.step02') }}
        </p>
        <p
          class="font-semibold <md:text-[12px] <md:font-medium <md:leading-[22px]"
        >
          {{ item.title }}
        </p>
        <p
          class="text-[#B3BAD1] <md:text-[12px] <md:font-normal <md:leading-[17px]"
        >
          <component :is="item.content" />
        </p>
      </div>
      <CommonAsset
        class="step-image absolute top-[16px] right-0"
        :name="item.imgName"
      />
    </li>
  </ul>
</template>

<script setup lang="tsx">
const { t } = useLang()
const stepList = [
  {
    title: t('kyc.help'),
    content: () => <>{t('kyc.agree')}</>,
    imgName: "images-kyc-step-1",
  },
  {
    title: t('kyc.document'),
    content: (
      <div>
        {t('kyc.choose')} <br />
        <p class="<md:text-[10px]">
          {t('kyc.refer')}
          <a
            href="/terms?layout=blank"
            target="_blank"
            class="text-[#3AD2F1] underline px-2"
          >
          {t('kyc.term')}
          </a>
          {t('kyc.accept')}
        </p>
      </div>
    ),
    imgName: "images-kyc-step-2",
  },
  {
    title: t('kyc.confirm01'),
    content: () => <>{t('kyc.confirm02')}</>,
    imgName: "images-kyc-step-3",
  },
  {
    title: t('kyc.face01'),
    content: () => <>{t('kyc.face02')}</>,
    imgName: "images-kyc-step-4",
  },
]
</script>
<style scoped lang="scss">
@media (min-width: 768px) {
  ul {
    li:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

.step-image {
  height: 64px !important;
}
</style>

import axios from "axios"
import HttpInstance from "~/request/server"
import { useAppStore } from "~/stores/app"
import { useUserStore } from "~/stores/user"

export enum EnumProcessContentType {
  IFRAME = 1,
  RESULT = 2,
}
export enum EnumResultContentType {
  Accepted = "Your verification has been accepted.",
  Rejected = "Your verification has been rejected.",
}

export const processContentType = shallowRef<EnumProcessContentType>(
  EnumProcessContentType.IFRAME
)
export const resultContentType = shallowRef<EnumResultContentType | String>(
  EnumResultContentType.Accepted
)

async function generateSnowflakeId() {
  const { data } = await axios.get(
    "https://www.onlineappzone.com/api/snowflake-id?limit=1"
  )
  return data[0]
}

type ResponseDataType = {
  code: number
  data: {
    player_id: string
    reference: string
    url: string
    status?: SuftiproVerificationStatus
  }
  msg: string
}
type ResponseDataTypeShuftipro = {
  country: "PH"
  email: string
  event: SuftiproVerificationStatus
  reference: string
  verification_url: string
}

type JourneyBuilderRequestData = {
  journey_id: string
  reference: string
  email: string
}

const shuftiproUrl = "https://api.shuftipro.com"
const shuftiproJourneyBuilderId = "fXkb4Fau1698292536"

export const generateShuftiproService = () => {
  const shuftiproClientId =
    "3e608a2af02fb27c0508439e13fdbe31f2bfebc3f32aeab2e9a8c800cbc2e7dc"
  const shuftiproSecretKey = "AMIIo6RUZGtD3uc9h2rNp0ecmNaZCArk"
  const token = btoa(`${shuftiproClientId}:${shuftiproSecretKey}`)
  return axios.create({
    baseURL: shuftiproUrl,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
    },
  })
}

export const useAiKyc = () => {
  const service = generateShuftiproService()
  const userStore = useUserStore()
  const appStore = useAppStore()

  /**
   * @example /api/v2/verify/data/Mk2ZGw0fCfrrbiETeM0QKiK7QdfbRbeNaHJbAfwFs9jTOBDkRqMJyUJT97MPpE95
   */
  const getVerifyReport = async (reportId: string) => {
    const response = await service.request({
      method: "GET",
      url: `/api/v2/verify/data/${reportId}`,
    })
    return response.data?.data
  }

  const getAiKycRequestStatus = async (props: { reference: string }) => {
    const response = await service.request({
      method: "POST",
      data: {
        reference: props.reference,
      },
    })

    return response.data
  }
  const getAiKycUrlByOfficialApi = async () => {
    if (userStore.info?.id && userStore.userKyc?.emailAddress) {
      const reference = `${await generateSnowflakeId()}`
      const requestData: JourneyBuilderRequestData = {
        journey_id: shuftiproJourneyBuilderId,
        reference,
        email: userStore.userKyc?.emailAddress ?? "",
      }
      const response = await service.request({
        data: requestData,
        method: "POST",
      })
      const responseData = response.data as ResponseDataTypeShuftipro
      const data: ResponseDataType = {
        code: 0,
        data: {
          player_id: `${userStore.info?.id}`,
          reference,
          url: responseData.verification_url,
        },
        msg: "",
      }
      return data
    } else {
      throw new Error("Invalid URL")
    }
  }

  const getAiKycUrl = async () => {
    console.log("getAiKycUrl")

    const response = await (HttpInstance.post(
      "kycGetUrl",
      {}
    ) as Promise<ResponseDataType>)
    console.log(response)

    if (response.code !== 0) {
      throw new Error(response.msg)
    }
    if (!response.data.url) {
      throw new Error("Invalid URL")
    }

    const kycDomainReplacement = (() => {
      try {
        return JSON.parse(
          appStore.getConfigByTitle("kyc_domain_replacement")?.value ?? "null"
        ) as {
          status: number
          pattern: string
          replacement: string
        }
      } catch (error) {
        return null
      }
    })()

    const urlReplaced =
      kycDomainReplacement && kycDomainReplacement.status
        ? response.data.url.replace(
            new RegExp(kycDomainReplacement.pattern),
            kycDomainReplacement.replacement
          )
        : response.data.url

    return {
      ...response,
      data: {
        ...response.data,
        url: urlReplaced,
      },
    }
  }

  const getUrl = () => {
    try {
      return getAiKycUrl()
    } catch (error) {
      console.log(error)

      return getAiKycUrlByOfficialApi()
    }
  }

  return {
    getUrl,
    getAiKycUrl,
    getAiKycUrlByOfficialApi,
    getAiKycRequestStatus,
    getVerifyReport,
  }
}

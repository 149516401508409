<template>
  <button
    v-if="width < 768 && !isBanner"
    ref="el"
    style="touch-action: none"
    class="drag-btn fixed flex flex-col gap-3px justify-center items-center z-10000 w-42px h-42px text-[9px] font-600"
    :style="style"
  >
    <CommonAsset
      class="w-14px h-14px"
      name="images-app-download-icon-download"
    />
    APP
  </button>
  <div
    v-if="width < 768 && isBanner"
    class="banner fixed z-99 bottom-70px w-full"
    
  >
    <div class="flex items-center pl-8px pr-23px">
      <button
        class="p-5px w-30px h-30px mr-5px"
        style="filter: brightness(0)"
        @click.stop="isBanner = false"
      >
        <CommonAsset class="w-15px" name="images-app-download-close" />
      </button>
      <p class="leading-64px text-16px font-600 text-black">
        Download Powerbtent APP
      </p>
      <CommonAsset
        class="absolute w-100px right-0 bottom-0"
        name="images-app-download-mascot"
      />
    </div>
  </div>
  <section
    v-if="visible"
    class="app fixed h-full z-10000 overflow-y-auto"
    :class="{ isIos }"
  >
    <button
      class="absolute w-30px h-30px top-30px right-20px"
      @click="visible = false"
    >
      <CommonAsset name="images-app-download-app-close" />
    </button>
    <CommonAsset v-if="isIos" name="images-app-download-ios-guide" />
    <CommonAsset v-else class="w-full" name="images-app-download-whole-bg" />
    <!-- <a :href="url" class="download-btn" download>
      <CommonAsset
        class="w-24px download-btn-arrow"
        name="images-app-download-arrow"
      />
      DOWNLOAD
    </a> -->
  </section>
</template>
<script setup lang="ts">
import { useDraggable } from "@vueuse/core"
import { isIOS } from "~/utils/device"

const visible = ref(false)
const route = useRoute()
const { width } = useWindowSize()
const isIos = computed(() => isIOS())
const url = computed(() =>
  isIOS() ? "/powerbtent web.mobileconfig" : import.meta.env.VITE_APP_DOWNLOAD_URL
)
const isBanner = ref(true)

const el = ref<HTMLElement | null>(null)
const { style } = useDraggable(el, {
  initialValue: { x: window.innerWidth - 68, y: window.innerHeight - 130 },
})

onMounted(() => {
  if (route.query.download) {
    visible.value = true
  }
})
</script>

<style scoped lang="scss">
.drag-btn {
  border-radius: 100%;

  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 100%;
    z-index: -1;
  }

  &::before {
    animation: spin 2s linear infinite;
    border-radius: 100%;
    background: linear-gradient(180deg, #febf34 11.98%, #f64b15 85.42%);
  }

  &::after {
    width: 34px;
    height: 34px;
    background: linear-gradient(180deg, #ffd139 12.5%, #f53e12 100%);
    box-shadow: 1px 1px 1px 0px #ffffff4d inset;
  }
}

.app {
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &.isIos {
    .download-btn {
      top: 520vw;
    }
  }
}

.banner {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
}

.download-btn {
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 14px;
  align-items: center;
  max-width: 410px;
  max-height: 115px;
  width: 54.8vw;
  height: 14.8vw;
  top: clamp(105.6vw, 105.6vw, 800px);
  border-radius: 200px;
  font-size: clamp(20px, 4.8vw, 36px);
  font-weight: 700;
  left: 50%;
  transform: translateX(-50%);

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 200px;
    background: linear-gradient(180deg, #ff8350 9.82%, #ff4b00 67.86%);
    z-index: -1;
  }

  &::after {
    bottom: -5px;
    background: linear-gradient(180deg, #ff8350 9.82%, #ea3b24 67.86%);
    z-index: -2;
  }

  &-arrow {
    animation: bounce 1s infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
</style>

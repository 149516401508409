<template>
  <section
    class="login-page w-full flex-1 form-container mt-40px <sm:(mt-20px)"
  >
    <slot />
  </section>
</template>
<style lang="scss" scoped>
@import "~/views/user/form.container.scss";
.form-container {
  @include formContainer;
}
</style>

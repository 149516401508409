<script setup lang="ts">
import { Teleport } from "vue"
import { popupProps } from "#imports"

const componentReactiveInitValue = {
  isLoading: false,
  isClosing: false,
}

const componentReactive = reactive<{
  isLoading: boolean
  isClosing: boolean
}>({
  ...componentReactiveInitValue,
})

const close = () =>
  new Promise((resolve) => {
    componentReactive.isClosing = true
    setTimeout(async () => {
      try {
        popupProps.show = false
        await popupProps.onClose?.()
        resolve(true)
      } catch (error) {
        resolve(false)
      }
    }, 500)
  })
const onBackdropClick = async () => {
  close()
}

const onConfirm = async () => {
  componentReactive.isLoading = true
  try {
    await popupProps.confirm?.()
    await close()
    componentReactive.isLoading = false
  } catch (error) {
    console.error(error)
    componentReactive.isLoading = false
  }
}

const onCancel = async () => {
  componentReactive.isLoading = true
  try {
    await popupProps.cancel?.()
    await close()
    componentReactive.isLoading = false
  } catch (error) {
    console.error(error)
    componentReactive.isLoading = false
  }
}

const content = computed(() => {
  if (typeof popupProps.content === "string") {
    return () => popupProps.content
  }
  return popupProps.content
})

watch(
  () => popupProps.show,
  (value) => {
    if (value) {
      Object.assign(componentReactive, componentReactiveInitValue)
    }
  }
)
</script>
<template>
  <Teleport v-if="popupProps.show" :to="popupProps.teleportTo">
    <div class="CommonPopup" @click.self="onBackdropClick">
      <div
        class="PopupContainer animate__animated animate__flipInX"
        :class="{
          animate__flipOutX: componentReactive.isClosing,
        }"
      >
        <div class="Inner space-y-[1em]">
          <div class="Header animate__animated animate__headShake">
            <div class="Title">
              {{ popupProps.title }}
            </div>
            <CommonAsset
              v-if="!popupProps.hideCloseButton"
              class="CloseIcon cursor-pointer"
              name="icons-close"
              @click="onBackdropClick"
            />
          </div>
          <div class="Content text-center">
            <component :is="content" v-if="popupProps.content" :="popupProps" />
          </div>
          <div
            class="Footer flex items-center justify-center w-full space-x-[1em]"
          >
            <CommonButton
              class="w-full"
              @click="onCancel"
              :disabled="
                componentReactive.isLoading || popupProps.cancelButton?.disabled
              "
              :loading="componentReactive.isLoading"
            >
              Cancel
            </CommonButton>
            <CommonButton
              class="w-full"
              :class="{
                'animate__animated animate__headShake animate__infinite':
                  componentReactive.isLoading,
              }"
              @click="onConfirm"
              :disabled="
                componentReactive.isLoading ||
                popupProps.confirmButton?.disabled
              "
              :loading="componentReactive.isLoading"
            >
              Confirm
            </CommonButton>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
.CommonPopup {
  --boxshadow-px: 5px;
  transition: all 0.3s ease-in-out;
  --dialog-bg-color: #262836;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1em;

  .PopupContainer {
    transition: all 0.5s ease-in-out;
    display: block;
    box-sizing: border-box;
    background-color: var(--dialog-bg-color);
    border-radius: 0.6em;
    max-height: 100%;
    padding: 1em;
    height: auto;
    /* box shadow */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);

    @screen <sm {
      width: 100%;
    }
  }

  .Inner {
    display: inline-flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    .Header {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      align-items: baseline;
      justify-content: center;

      .Title {
        font-size: 1.2em;
        font-weight: bold;
        color: #fff;
        min-width: 300px;
        text-align: center;
      }

      .CloseIcon {
        position: absolute;
        top: 0;
        right: 0;
        width: 1.5em;
        height: 1.5em;
        color: #fff;
      }
    }

    .Content {
      width: 100%;
      max-height: 600px;
      overflow-y: auto;

      @screen <sm {
        width: 100%;
        max-height: 100%;
      }
    }
  }
}
</style>

<template>
  <slot />
</template>
<script setup lang="ts">
import { useUserStore } from "~/stores/user"
import { useMessage } from "~/stores/message"
import { initNOZoom } from "~/utils/initialize"
import { useCheckInStore } from "~/stores/checkIn"
import { useGlobalInit } from "#imports"
import { useDialog } from "~/composables/useDialog"
import { usePromoCodeManager } from "~/composables/usePromoCodeManager"

await useGlobalInit().init()

const userStore = useUserStore()
const message = useMessage()
const route = useRoute()
const checkInStore = useCheckInStore()
const promoCodeManager = usePromoCodeManager()

// 初始化存入邀请码
const initPromoCode = () => {
  const promoCode = route.query?.promoCode as string
  if (promoCode) {
    promoCodeManager.savePromoCode(promoCode)
    if (!userStore.isLogin) {
      useDialog().openDialog("signIn", "PromoCode")
    }
  }
}

const initAuth = async () => {
  if (userStore.isLogin) {
    try {
      await message.GET_NOTICE_COUNT()
      await userStore.GET_USER_KYC()
    } catch (e) {
      console.log(e)
    }
  }
}

onMounted(async () => {
  if (isInIframe()) {
    window.parent.postMessage(JSON.stringify({ name: "closeGame" }), "*")
  }
  initNOZoom()
  await useGlobalInit().thirdPartyAccessCheck()
  await initAuth()
  await initPromoCode()
  await checkInStore.CHECK_IN_STORY()
})
</script>

<template>
  <div class="scroll-area" ref="scrollArea">
    <el-scrollbar ref="scrollbarRef" always @scroll="scroll">
      <div ref="innerRef">
        <slot />
      </div>
    </el-scrollbar>
    <el-slider
      :vertical="true"
      :show-tooltip="false"
      v-model="value"
      :max="max"
      @input="inputSlider"
    />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, nextTick } from "vue"
import { ElScrollbar } from "element-plus"

const max = ref(0)
const value = ref(0)
const innerRef = ref<HTMLDivElement>()
const scrollArea = ref<HTMLDivElement>()
const scrollbarRef = ref<InstanceType<typeof ElScrollbar>>()

onMounted(() => {
  nextTick(() => {
    const areaHeight = scrollArea.value!.clientHeight
    const innerHeight = innerRef.value!.clientHeight - areaHeight - 24
    max.value = innerHeight
    value.value = innerHeight
  })
})

const inputSlider = (value: number) => {
  scrollbarRef.value!.setScrollTop(max.value - value)
}
const scroll = ({ scrollTop }: { scrollTop: number }) => {
  value.value = max.value - scrollTop
}
</script>

<style lang="scss">
.scroll-area {
  height: 100%;
  display: flex;
  position: relative;

  .el-scrollbar {
    flex: 1;

    &__bar {
      display: none;
    }
  }

  .el-slider {
    &.is-vertical {
      position: absolute;
      right: 0;
      bottom: 0;
      margin: 0;
      height: calc(100% - 24px);
    }

    &__bar,
    &__runway {
      background-color: transparent;
    }

    &.is-vertical &__runway {
      margin: 0 2px 0 6px;
    }

    &.is-vertical &__button-wrapper {
      height: auto;
      width: auto;
      left: auto;
      transform: translate(0, 0);
    }

    &__button {
      background: darkgrey;

      &.hover {
        transform: scale(1);
      }

      border-radius: 8px;
      width: 6px;
      height: 24px;
      border: 0;
    }
  }
}
</style>

<template>
  <div
    :style="{
      'min-height': minHightValue,
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
import type { CSSProperties } from "vue"
const route = useRoute()
const minHightValue = shallowRef<CSSProperties["min-height"]>("unset")

watch(
  () => route.name,
  (routeName) => {
    switch (routeName) {
      case "PromotionInfo":
        minHightValue.value = "unset"
        break
      default:
        minHightValue.value = "unset"
        break
    }
  },
  {
    immediate: true,
  }
)
</script>

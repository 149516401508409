<template>
  <LayoutsFormSection>
    <ElForm
      ref="ruleFormRef"
      :model="ruleForm"
      class="demo-ruleForm"
      status-icon
    >
      <FormMobileItem
        v-model="ruleForm.mobile"
        :areacode="ruleForm.areaCode"
        :is-need-original="true"
        :formatter-fn="mobileFormatters"
        @area-change="onAreaChange"
      >
        <FormVerifyButton
          smstype="REG_LOGIN"
          :areacode="ruleForm.areaCode"
          :value="ruleForm.mobile"
          :is-not-same-phone-number="isNotSamePhoneNumber"
        />
      </FormMobileItem>
      <FormVerifyCode v-model="ruleForm.verifyCode" />
      <ElFormItem class="mt-30px">
        <CommonButton
          :loading="loading"
          :disabled="signInButtonDisabled"
          class="login-button w-full justify-center"
          type="primary"
          @click.prevent="submitLogin"
        >
          Sign in
        </CommonButton>
      </ElFormItem>
    </ElForm>
  </LayoutsFormSection>
</template>

<script setup lang="ts">
import { ElForm, ElFormItem } from "element-plus"
import type { FormInstance } from "element-plus"
import { useUserStore } from "~/stores/user"
import { useDialog } from "~/composables/useDialog"

const route = useRoute()
const user: any = useUserStore()
type RuleFormType = {
  areaCode: string
  mobile: string
  verifyCode: number | string
}
const loading = shallowRef<boolean>(false)
const ruleFormRef = ref<FormInstance>()
const ruleForm: RuleFormType = reactive({
  areaCode: "+886",
  mobile: "",
  verifyCode: "",
})
const isNotSamePhoneNumber = computed(() => {
  const { mobile } = ruleForm
  const mobileNumber = removePhoneZero(mobile)
  return mobileNumber !== user.info?.mobile
})
const signInButtonDisabled = computed(() => {
  return (
    isNotSamePhoneNumber.value || ruleForm.verifyCode.toString().length !== 6
  )
})

const removePhoneZero = (phoneNumber: string) => {
  if (phoneNumber.charAt(0) === "0")
    return phoneNumber.slice(1, phoneNumber.length)
  else return phoneNumber
}

const submitLogin = () => {
  ruleFormRef?.value?.validate(async (v: boolean) => {
    if (v) {
      loading.value = true
      // 電話號碼統一去掉開頭0再傳,使用者可以輸入或不輸入0
      const { mobile, ...formData } = ruleForm
      const mobileNumber = removePhoneZero(mobile)
      try {
        const { code } = await user.MOBILE_LOGIN({
          mobile: mobileNumber,
          ...formData,
        })
        if (code === 0) {
          useDialog().closeDialog()
        }
      } catch (e) {
        console.log(e)
      } finally {
        loading.value = false
      }
    }
  })
}

const mobileFormatters = (val: string) => {
  ruleForm.mobile = formatters.mobile(val.toString())
}

const onAreaChange = (value: string) => {
  ruleForm.areaCode = value
}

const timeOut = setTimeout(() => {
  delete route.query.dialogOptions
  useDialog().openDialog("PwdSign")
}, 900000)

onMounted(() => timeOut)
onUnmounted(() => clearTimeout(timeOut))
</script>

import { TeleportProps } from "vue"

type PopupType = {
  teleportTo: TeleportProps["to"]
  show: boolean
  title: string
  content: string | ((props: PopupType) => JSX.Element)
  confirm?(): void
  cancel?(): void
  onClose?(): void
  hideCloseButton?: boolean
  confirmButton?: {
    text?: string
    type?: string
    disabled?: boolean
  }
  cancelButton?: {
    text?: string
    type?: string
    disabled?: boolean
  }
}

export const popupProps = reactive<PopupType>({
  teleportTo: "body",
  show: false,
  title: "Reminder",
  content: "No Content.",
  hideCloseButton: false,
  async confirm() {
    throw new Error("Function'confirm' not implemented.")
  },
  async cancel() {
    throw new Error("Function 'cancel' not implemented.")
  },
  async onClose() {
    throw new Error("Function 'onClose' not implemented.")
  },
  confirmButton: {
    text: "Confirm",
    disabled: false,
  },
  cancelButton: {
    text: "Cancel",
    disabled: false,
  },
})

export function usePopupHook() {
  const open = (props: Partial<PopupType>): void => {
    Object.assign(popupProps, props)
    popupProps.show = true
  }
  const close = (): void => {
    popupProps.show = false
    popupProps.teleportTo = undefined
  }

  return {
    open,
    close,
  }
}

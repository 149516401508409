<script setup lang="ts">
const route = useRoute()

type OutputType = {
  title: string
  meta: {
    itemprop?: string
    name: string
    content: string
    key?: string
  }[]
  link?: {
    rel: string
    href: string
  }[]
}

const head = computed(() => {
  let output: OutputType
  switch (route.name) {
    case "Slots": {
      output = {
        title: "Slot at Powerbtent | Register Now and Get 100% Bonus",
        meta: [
          {
            name: "description",
            content:
              "Discover the exciting world of Powerbtent Slot Games with a wide variety of captivating themes, stunning visuals, and thrilling gameplay.",
            key: "Slot at Powerbtent",
          },
          {
            name: "keywords",
            content: "Slot Games, JILI Slot, JDB Slot",
          },
        ],
      }
      break
    }
    case "Live": {
      output = {
        title:
          "Live Casino at Powerbtent | Experience the Thrill of Live Casino Gaming",
        meta: [
          {
            name: "description",
            content:
              "Live casino at Powerbtent! Blackjack, roulette, baccarat, and other popular table games will be explained to you by our highly qualified dealers.",
            key: "Live Casino",
          },
          {
            name: "keywords",
            content: "Live Casino, EVO Live",
          },
        ],
      }
      break
    }
    case "Fishing": {
      output = {
        title: "Fishing Games at Powerbtent | Catch Big Wins | Register Now",
        meta: [
          {
            name: "description",
            content:
              "Check out Powerbtent fishing games! Cast your line and reel in large rewards in these fishing games with amazing graphics and entertaining action.",
            key: "Fishing",
          },
          {
            name: "keywords",
            content: "Fishing, JILI Fish, JDB Fishing",
          },
        ],
      }
      break
    }
    case "Chess": {
      output = {
        title: "Chess at Powerbtent | Play Chess Online and Win Big Rewards",
        meta: [
          {
            name: "description",
            content:
              "Powerbtent online casino is the perfect place to play chess online. Our user-friendly platform is designed for players of all skill levels, and our games are fair and secure. With generous bonuses and promotions, you could win big while mastering your chess strategy.",
            key: "Chess at Powerbtent",
          },
          {
            name: "keywords",
            content: "Chess, JILI Chess, CQ9 Chess",
          },
        ],
      }
      break
    }
    case "Sports": {
      output = {
        title:
          "Sports Betting at Powerbtent | Bet on Your Favorite Teams and Win Big",
        meta: [
          {
            name: "description",
            content:
              "Powerbtent online casinos have always made sports betting more challenging and fun. Our sportsbook is user-friendly and tailored to players.",
            key: "Sports Betting at Powerbtent",
          },
          {
            name: "keywords",
            content: "Sports, Powerbtent Sports, Pb Sports, Fb Sports",
          },
        ],
      }
      break
    }
    case "Table": {
      output = {
        title: "Table Games at Powerbtent | Play Classic and Modern Casino Online",
        meta: [
          {
            name: "description",
            content:
              "Experience the thrill of Powerbtent Table Games whether you like classics like blackjack, roulette, or poker or want to try something new.",
            key: "Table Games at Powerbtent",
          },
          {
            name: "keywords",
            content: "Table Games, TPG Table",
          },
        ],
      }
      break
    }
    case "Bingo": {
      output = {
        title:
          "Bingo Games at Powerbtent | Discover Exciting Bingo Surprises and Win",
        meta: [
          {
            name: "description",
            content:
              "Play the most exciting online bingo games 24/7 at Powerbtent Casino! Choose from 75-ball, 80-ball, and 90-ball bingo. Compete for huge jackpots.",
            key: "Bingo Games at Powerbtent",
          },
          {
            name: "keywords",
            content: "Bingo, JDB Bingo",
          },
        ],
      }
      break
    }
    case "Affiliate-Rule": {
      output = {
        title:
          "Powerbtent Affiliate Earn Passive Income Online Casino Affiliate Program",
        meta: [
          {
            name: "description",
            content:
              "Powerbtent online casino is proud to offer a generous and rewarding affiliate program to our partners. With competitive commission rates, easy tracking, and a range of promotional materials, our program makes it easy to earn passive income by promoting our casino to your audience.",
            key: "powerbtent affililiate",
          },
          {
            name: "keywords",
            content: "powerbtent affililiate",
          },
        ],
      }
      break
    }
    case "Promotions": {
      output = {
        title:
          "Promotions at Powerbtent | Get Rewarded with Online Casino Latest Promotions",
        meta: [
          {
            name: "description",
            content:
              "Powerbtent online casino promotions provide amazing deals including welcome bonuses, cashback, and free spins etc. That you should not miss out.",
            key: "Promotions at Powerbtent",
          },
          {
            name: "keywords",
            content: "Promotion, Free spins, Welcome Bonuses",
          },
        ],
      }
      break
    }
    case "blog": {
      output = {
        title: "Powerbtent Blog | Your Source for Expert Insights in Online Casino",
        meta: [
          {
            name: "description",
            content:
              "Want to stay informed about the latest trends and news in the world of online gambling? Check out Powerbtent online casino's blog! Our team of experts shares their insights and experiences on a wide range of topics, from new game releases to tips and tricks for winning big.",
            key: "powerbtent blogs",
          },
          {
            name: "keywords",
            content: "powerbtent blogs",
          },
        ],
      }
      break
    }
    case "Vip": {
      output = {
        title: "Powerbtent Vip Online Casino Program and Unlock Exclusive Rewards",
        meta: [
          {
            name: "description",
            content:
              "Ready to take your gaming experience to the next level? Join Powerbtent online casino's VIP program and enjoy exclusive benefits like personalized support, higher deposit and withdraw limits, and access to exclusive games and promotions. Our VIP program is designed to reward our most loyal and dedicated players.",
            key: "powerbtent vip",
          },
          {
            name: "keywords",
            content: "powerbtent vip",
          },
        ],
      }
      break
    }
    case "HelpCenter":
      output = {
        title:
          "Help-center at Powerbtent | 24/7 Reliable Help is a Click Away at Powerbtent",
        meta: [
          {
            name: "description",
            content:
              "Check the Powerbtent Help-Center 24/7 for answers. Deposits, withdrawals, technical concerns, promotions, responsible gambling, and more.",
            key: "Help-center at Powerbtent",
          },
          {
            name: "keywords",
            content: "Help Center, Withdrawals, Registration",
          },
        ],
      }
      break
    default: {
      output = {
        title: "Powerbtent | Trusted Destination for Legal Online Casino Gaming",
        meta: [
          {
            name: "description",
            content:
              "Our premier casino platform at Atg.club provides an immersive online wagering experience, including live casino, classic slots, and more.",
            key: "Powerbtent",
          },
          {
            name: "keywords",
            content: "Home, Slot Games, Live Casino",
          },
        ],
      }
      break
    }
  }
  const description = output.meta.find((i) => i.name === "description")
  const keywords = output.meta.find((i) => i.name === "keywords")
  const fixedOutput: OutputType = {
    ...output,
    meta: [
      ...output.meta,
      {
        name: "twitter:title",
        content: output.title,
      },
      {
        name: "twitter:description",
        content: description?.content ?? "",
      },
      {
        name: "twitter:creator",
        content: keywords?.content ?? "",
      },
      {
        name: "description",
        itemprop: "description",
        content: description?.content ?? "",
      },
      {
        name: "name",
        itemprop: "name",
        content: output.title,
      },
      {
        name: "title",
        itemprop: "title",
        content: output.title,
      },
      {
        name: "og:site_name",
        content: output.title,
      },
      {
        name: "og:title",
        content: output.title,
      },
      {
        name: "og:url",
        content: `https://atg.club${route.path}`,
      },
      {
        name: "og:description",
        content: description?.content ?? "",
      },
    ],
    link: [
      {
        rel: "canonical",
        href: `https://atg.club${route.path}`,
      },
    ],
  }
  return fixedOutput
})

watchEffect(() => {
  useHead(head.value)
})
</script>
<template>
  <slot />
</template>

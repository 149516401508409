<template>
  <div>
    <div class="kyc-step-title">
      <CommonAsset
        v-if="isBackIconDisplay"
        class="kyc-step-back-icon absolute w-[25px] cursor-pointer"
        name="icons-back"
        @click="kycFormManager.previousStep"
      />
      <h3 class="mt-[-40px] text-[12px] text-[#B3BAD1]">
        {{ kycFormManager.StepTitle.value }}
      </h3>
    </div>
    <section class="form-container <sm:mt-[10px]">
      <ElForm
        ref="formRef"
        :model="kycFormManager.form"
        :rules="rules"
        class="demo-ruleForm"
        status-icon
        :disabled="isFormDisabled"
        :style="{
          'overflow-y': isOverflowYScroll ? 'scroll' : 'unset',
        }"
        v-if="userStore.kycIsVisibleData && userStore.kycIsVisibleData.length"
      >
        <slot />
        <div
          v-if="isKycStatusTitleShow"
          :data-color="previewStatusColor"
          class="preview-status absolute top-0 flex items-center justify-center w-3/5 transform -translate-x-1/2 py-4px text-12px left-1/2"
        >
          <Icon class="text-[1.7em]" :name="iconName" />
          <span class="ml-8px text-[1.2em] font-600 text-shadow-md">
            {{ kycStatusTitle }}
          </span>
        </div>
        <ViewsUserKycFormItemConfirmButton v-if="isConfirmButtonDisplay" />
      </ElForm>
    </section>
  </div>
</template>
<script setup lang="ts">
import { ViewsUserKycFormItemConfirmButton, Icon } from "#components"
import { useKycForm, aiKycSwitchStatusSync } from "#imports"
import { useUserStore } from "~/stores/user"
import { EnumKycStatus } from "~/types/enum"

const kycFormManager = useKycForm()
const rules = useKycFormRules()
const {
  formRef,
  isFormDisabled,
  kycStatus,
  currentStep,
  isKycStatusTitleShow,
  getLastestKycData,
} = kycFormManager
const { getKycStatusTitle } = useDictionary()
const userStore = useUserStore()
const isBackIconDisplay = computed(() => {
  const kycStatus = userStore.GET_UESR_KYC_STATUS_ACTION()
  if (currentStep.value === KycSceneStep.AI_KYC_STEP) {
    switch (kycStatus) {
      case EnumKycStatus.AI_APPROVED:
        return false
    }
    return true
  }
  return [kycFormManager.currentStep.value === KycSceneStep.AI_KYC_STEP].every(
    Boolean
  )
})

const removeSelectStatus = (ev: Event) => {
  const activeEl = document.activeElement as HTMLElement | null
  const activeElContent = document.activeElement?.closest(
    "div.el-form-item__content"
  )?.firstElementChild as HTMLElement | null
  if (activeEl && activeElContent) {
    const activeElClassName = [...activeElContent.classList]
    if (activeElClassName.includes("el-select")) {
      activeEl.blur()
    }
  }
}

const isConfirmButtonDisplay = computed(() => {
  switch (currentStep.value) {
    case KycSceneStep.FINAL_STEP:
      if (aiKycSwitchStatusSync.value) {
        return [
          EnumKycStatus.CSR_REJECTED,
          EnumKycStatus.AI_APPROVED,
          EnumKycStatus.AI_REJECTED,
          EnumKycStatus.AI_SKIPPED,
        ].includes(kycStatus.value)
      }
      return [EnumKycStatus.INITIAL, EnumKycStatus.CSR_REJECTED].includes(
        kycStatus.value
      )
    case KycSceneStep.FIRST_STEP:
      return true
    case KycSceneStep.AI_KYC_STEP:
      return false
  }
})

onMounted(() => {
  const el = document.getElementsByClassName("show-content")[0]
  el?.addEventListener("scroll", removeSelectStatus)
})

onUnmounted(() => {
  const el = document.getElementsByClassName("show-content")[0]
  el?.removeEventListener("scroll", removeSelectStatus)
})

const isOverflowYScroll = computed(() => {
  if (currentStep.value === KycSceneStep.AI_KYC_STEP) {
    return false
  }
  return true
})

const kycStatusTitle = computed(() => getKycStatusTitle())

const previewStatusColor = computed(() => {
  switch (kycStatus.value) {
    case EnumKycStatus.COMPLETED:
    case EnumKycStatus.CSR_APPROVED:
      return "green"
    case EnumKycStatus.CSR_REJECTED:
    case EnumKycStatus.BLOCKED:
      return "red"
    case EnumKycStatus.AI_REJECTED:
      return "orange"
    case EnumKycStatus.AI_SKIPPED:
      return "yellow"
    case EnumKycStatus.AI_APPROVED:
    case EnumKycStatus.CERTIFY:
    case EnumKycStatus.REVERIFY:
      return "progress"
  }
})

const iconName = computed(() => {
  switch (kycStatus.value) {
    case EnumKycStatus.COMPLETED:
    case EnumKycStatus.CSR_APPROVED:
      return "mdi:checkbox-marked-circle-auto-outline"
    case EnumKycStatus.BLOCKED:
      return "mdi:block-helper"
    case EnumKycStatus.CSR_REJECTED:
      return "mdi:account-alert"
    case EnumKycStatus.AI_REJECTED:
      return "mdi:account-question"
    case EnumKycStatus.AI_SKIPPED:
      return "mdi:account-cog"
    case EnumKycStatus.AI_APPROVED:
      return "mdi:account-check"
    case EnumKycStatus.CERTIFY:
    case EnumKycStatus.REVERIFY:
      return "mdi:account-search"
    default:
      return "mdi:clipboard-clock"
  }
})

onMounted(async () => {
  await getLastestKycData()
})
</script>
<style lang="scss" scoped>
@import "~/views/user/form.container.scss";
.form-container {
  @include formContainer;
}

.kyc-step-title {
  position: sticky;
  top: 10px;
  z-index: 10;
  background: var(--v-dialog-bg);
  padding-bottom: 10px;
  transform: translateY(-10px);
  user-select: none;
  @screen <sm {
    transform: translateY(-20px);
    top: 20px;
  }
}

.kyc-step-back-icon {
  transform: scale(1.5);
}

.preview-status {
  box-shadow: #000 0px 0px 5px;

  background: linear-gradient(
    180deg,
    #1999ee -9.78%,
    #115bca 26.02%,
    #0b3aa8 111.96%
  );
  border-radius: 0 0 20px 20px;
  &[data-color="green"] {
    background: linear-gradient(
      180deg,
      #00d16c -9.78%,
      #00a84f 26.02%,
      #008a3d 111.96%
    );
  }
  &[data-color="orange"] {
    background: linear-gradient(
      180deg,
      #ff9f00 -9.78%,
      #e67e00 26.02%,
      #d16e00 111.96%
    );
  }
  &[data-color="progress"] {
    background: linear-gradient(
      180deg,
      #1999ee -9.78%,
      #115bca 26.02%,
      #0b3aa8 111.96%
    );
  }
  &[data-color="red"] {
    background: linear-gradient(
      180deg,
      #ff4d4d -9.78%,
      #e62e2e 26.02%,
      #d11f1f 111.96%
    );
  }
}
</style>

<script setup lang="ts">
import type { FormItemInstance } from "element-plus"
import { useAppStore } from "~/stores/app"
const app = useAppStore()

const props = withDefaults(
  defineProps<{
    formatterFn?: Function
    isNeedOriginal?: boolean
  }>(),
  {
    isNeedOriginal: false,
    formatterFn: () => {},
  }
)

const ruleForm = ref<FormItemInstance>()
const { cityList } = useCityList()
const { t } = useLang()
const { checkMobileOriginal, checkMobile } = useCheck()
const rules = computed(() => [
  {
    required: app.registerItemData?.find((item:any) => item.validKey === "phone")?.requiredEnabled,
    validator: (
    rule: any,
    value: string,
    cb: (arg?: string) => void
  ) => {
    if (!value){
      if (app.registerItemData?.find((item:any) => item.validKey === "phone")?.requiredEnabled) {
        return cb("Please enter your mobile number")
      }else{
        cb()
      }
    }
    if (!isMobile(value)) return cb("Please enter the correct mobile")
    cb()
  },
    trigger: "change",
  },
])
const attr = useAttrs()
const maxLength = computed(() => {
  const value = (attr.modelValue as string) ?? ""
  if (value.startsWith("0")) {
    return 11
  }
  if (value.startsWith("9")) {
    return 10
  }
  return 11
})

const emit = defineEmits(["area-change"])
const handleAreaChange = (value: string) => {
  emit("area-change", value)
}
</script>
<template>
  <ElFormItem
    ref="ruleForm"
    :rules="rules"
    prop="mobile"
    :label="t('sign.phone')"
    class="prefix-input"
  >
    <ElInput
      :maxlength="maxLength"
      type="tel"
      inputmode="numeric"
      :placeholder="t('sign.phone')"
      v-bind="$attrs"
      @input="props.formatterFn"
    >
      <template #prefix>
        <el-select
          :model-value="($attrs.areacode as string)"
          class="area-code w-80px"
          :placeholder="t('public.Select')"
          @change="handleAreaChange"
        >
          <el-option
            v-for="item in cityList"
            :key="`el-option-${item.code}`"
            :label="`+${item.code}`"
            :value="`+${item.code}`"
          >
            <span>{{ `${item.en} +${item.code}` }}</span>
          </el-option>
        </el-select>
      </template>
      <template #suffix>
        <slot />
      </template>
    </ElInput>
  </ElFormItem>
</template>

import { FunctionalComponent } from "vue"

export enum EnumDialogTemplate {
  DEFAULT_404 = "Default404",
  WITHDRAW_REMINDER = "WithdrawReminder",
}

let templates: Record<EnumDialogTemplate, Component>

export async function importTemplates() {
  if (!templates) {
    const temp = await import.meta.glob(
      "~/components/common/Dialog/Template/*.vue",
      {
        eager: true,
        import: "default",
      }
    )
    templates = Object.fromEntries(
      Object.values(temp).map((c) => {
        // @ts-ignore
        return [c.__name, c]
      })
    )
  }
  return templates
}

const customTitle = ref<FunctionalComponent | string>()
const componentReady = shallowRef(false)
const templateName = shallowRef<EnumDialogTemplate>()
const onCloseCallback = shallowRef<() => void>()
const onConfirm = shallowRef<() => void>()

export const useDialogHook = (from?: MiddlewareFromType) => {
  const route = (() => from ?? useRoute())()
  const open = async (
    name: EnumDialogTemplate,
    options: {
      title?: FunctionalComponent | string
      disabledNavigate?: boolean
      onClose?: () => void
      onConfirm?: () => void
    } = {}
  ) => {
    if (options.onClose) {
      onCloseCallback.value = options.onClose
    }
    if (options.title) {
      customTitle.value = options.title
    }
    if (options.onConfirm) {
      onConfirm.value = options.onConfirm
    }
    if (options.disabledNavigate) {
      templateName.value = name
    } else {
      return navigateTo(
        {
          ...route,
          query: {
            ...route.query,
            dialogName: name,
          },
        },
        {
          replace: true,
        }
      )
    }
  }
  const close = async () => {
    await navigateTo(
      { ...route, query: { ...route.query, dialogName: undefined } },
      {
        replace: true,
      }
    )
    await onCloseCallback.value?.()
    onCloseCallback.value = undefined
  }

  return {
    open,
    close,
    controls: {
      componentReady,
      customTitle,
      templateName,
      onConfirm,
    },
  }
}

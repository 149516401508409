<script setup lang="tsx">
import { onMounted, onUnmounted } from "vue"
import { UserAiKycIframe } from "#components"
import { useKycForm, lockNx } from "#imports"
import { EnumKycStatus } from "~/types/enum"
type IframeEventPayloadType = Parameters<
  NonNullable<InstanceType<typeof UserAiKycIframe>["onMessage-event"]>
>[0]

const { useKycStatusListener, getLastestKycData } = useKycForm()

const onMessageEvent = (payload: IframeEventPayloadType) => {
  console.log(payload.verification_status, payload)
  switch (payload.verification_status) {
    case "verification.cancelled":
      break
    case "verification.declined":
    case "verification.accepted":
      break
  }
}
const { startListen, stopListen } = useKycStatusListener()
const __kycStatus = useKycStatus()
const kycStatus = shallowRef(__kycStatus.value)
const isStatusLabelShow = shallowRef(false)

const countDownNumber = shallowRef(5)
const timer = ref<NodeJS.Timeout | null>(null)

const startCountDown = (callback: () => void) => {
  const later = Date.now() + countDownNumber.value * 10 ** 3
  timer.value = setInterval(() => {
    const updateNumber = Math.ceil((later - Date.now()) / 1000)
    countDownNumber.value = updateNumber <= 0 ? 0 : updateNumber
    if (countDownNumber.value === 0) {
      clearInterval(timer.value!)
      callback()
    }
  }, 500)
}

const onKycStatusChange = (status: EnumKycStatus) => {
  kycStatus.value = status
  switch (status) {
    case EnumKycStatus.AI_APPROVED:
      startCountDown(async () => {
        await getLastestKycData()
        useNotify({
          title: "Prompt",
          message: "Please edit basic information first!",
          position: "top-left",
        })
      })
      break
    case EnumKycStatus.AI_REJECTED:
      startCountDown(async () => {
        await getLastestKycData()
        useNotify({
          title: "Prompt",
          message: "Please edit basic information first!",
          position: "top-left",
        })
      })
      break
    case EnumKycStatus.AI_SKIPPED:
      startCountDown(async () => {
        await getLastestKycData()
        useNotify({
          title: "Prompt",
          message:
            "AI-KYC currently down, Please edit basic information directly!",
          position: "top-left",
        })
      })
      break
  }
}

onMounted(() => {
  lockNx.value = true
  startListen(onKycStatusChange)
})
onUnmounted(() => {
  lockNx.value = false
  stopListen()
})

function StatusLabel() {
  const statusLabelText = computed(() => {
    switch (kycStatus.value) {
      case EnumKycStatus.CERTIFY:
      case EnumKycStatus.REVERIFY:
        return "Pending"
      case EnumKycStatus.CSR_APPROVED:
      case EnumKycStatus.COMPLETED:
        return "Approved"
      case EnumKycStatus.CSR_REJECTED:
        return "Rejected"
      default:
        return "Unsubmitted"
    }
  })
  const colorSyntax = computed(() => {
    switch (kycStatus.value) {
      case EnumKycStatus.AI_APPROVED:
        return "bg-green-600"
      case EnumKycStatus.AI_REJECTED:
        return "bg-red-800"
      case EnumKycStatus.AI_SKIPPED:
        return "bg-yellow-500"
      default:
        return "bg-dark-50"
    }
  })
  const text = computed(() => {
    switch (kycStatus.value) {
      case EnumKycStatus.AI_APPROVED:
        return `Status: Approved, to next step in ${countDownNumber.value} seconds`
      case EnumKycStatus.AI_REJECTED:
        return `Status: Rejected, to next step in ${countDownNumber.value} seconds`
      case EnumKycStatus.AI_SKIPPED:
        return `Status: Skipped, to next step in ${countDownNumber.value} seconds`
      default:
        return `Status: ${statusLabelText.value}`
    }
  })
  return (
    // @ts-ignore
    <div
      id="aikyc-status-label"
      class={`absolute left-[0.8em] top-[0.8em] z-1 p-2 py-1 rounded text-white font-semibold ${colorSyntax.value}`}
    >
      {text.value}
    </div>
  )
}
</script>

<template>
  <div class="relative">
    <StatusLabel v-if="isStatusLabelShow" />
    <UserAiKycIframe
      @message-event="onMessageEvent"
      @iframe-loaded="isStatusLabelShow = true"
    />
  </div>
</template>
<style lang="scss" scoped>
#aikyc-status-label {
  transition: all 0.3s ease-in-out;
  @screen <sm {
    font-size: 0.8em;
  }
}
</style>

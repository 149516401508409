<script setup lang="tsx">
import { defineExpose, shallowRef } from "vue"
import { CommonAsset, CommonButton } from "#components"
import { useDictionary } from "#imports"
import { useWallet } from "~~/stores/wallet"
import { StoreTypes } from "~/types/store"
import { usePopupHook } from "#imports"
import HttpInstance from "~/request/server"

const emit = defineEmits<{
  (event: "confirm", data: any): void
}>()
const wallet = useWallet()
const data = computed(() => {
  return wallet.withdrawDetail
})
const isLoaded = shallowRef(false)
const isPending = shallowRef(false)
const popupHook = usePopupHook()

const reminderList = computed(() => {
  return [
    {
      label: "Total Deduction",
      text: (
        Number(data.value?.auditFee || 0) + Number(data.value?.bonus || 0)
      ).toFixed(2),
    },
    {
      label: "Withdrawable Amount",
      text: Number(data.value?.actualWithdrawal || 0).toFixed(2),
    },
  ]
})

async function confirm() {
  isPending.value = true

  popupHook.open({
    teleportTo: "#id-common-dialog",
    title: "Reminder",
    content() {
      const unaccalimedBonus = Number(data.value?.unaccalimedBonus) || 0
      return (
        <ul class="flex flex-col justify-center items-start space-y-[0.5em]">
          {reminderList.value.map((item) => {
            return (
              <li class="flex flex-row justify-between items-center w-full">
                <span class="font-500">{item.label}:</span>
                <span>${item.text}</span>
              </li>
            )
          })}
          {unaccalimedBonus ? (
            <>
              <li class="text-red-600">
                Are you sure giving up unclaimed bonus?
              </li>
              <li class="text-red-600  text-right w-full">
                <span class="font-semibold">Total: </span>
                <span>${unaccalimedBonus.toFixed(2)}</span>
              </li>
            </>
          ) : null}
        </ul>
      )
    },
    async confirm() {
      try {
        const __data = Object.assign({}, data.value)
        const response = await HttpInstance.post("cleanUserCodeRecords", {
          ...__data,
        })
        useNotify({
          type: "success",
          // @ts-ignore
          message: response.msg,
          position: "top-left",
        })
        emit("confirm", response)
        return response
      } catch (error) {
        console.log("error", error)
      } finally {
        isPending.value = false
      }
    },
    cancel() {
      // TODO
    },
    onClose() {
      isPending.value = false
    },
  })
}

const agreementChecked = shallowRef(false)
const checkIconName = computed(() => {
  return agreementChecked.value ? "icons-check-green" : "icons-uncheck-green"
})
const onAgreementClick = (e: Event) => {
  e.preventDefault()
  e.stopPropagation()
  agreementChecked.value = true
}

const isActualWithdrawalLessThanMinWithdrawalAmount = computed(() => {
  return Number(data.value?.minAmount) > Number(data.value?.actualWithdrawal)
})

const isConfirmButtonDisabled = computed(() => {
  return (
    !agreementChecked.value ||
    isPending.value ||
    isActualWithdrawalLessThanMinWithdrawalAmount.value
  )
})

function Footer() {
  return (
    <div
      style={{
        display: isActualWithdrawalLessThanMinWithdrawalAmount.value
          ? "none"
          : "flex",
      }}
      class="flex flex-col items-center w-full space-y-[1em]"
    >
      <CommonButton
        class="w-full"
        onClick={confirm}
        disabled={isConfirmButtonDisabled.value}
      >
        Confirm
      </CommonButton>
      <div
        class="inline-flex flex-row items-center self-start space-x-2 cursor-pointer"
        onClick={onAgreementClick}
      >
        <CommonAsset class="w-[1em]" name={checkIconName.value} />
        <p class="text-[0.7em]">I agree to the above deductions and fees.</p>
      </div>
    </div>
  )
}
function Title() {
  return <div>Reminder</div>
}

defineExpose({
  Title: () => <Title />,
  Footer: () => <Footer />,
  isLoaded,
  beforeClose: () => {
    isLoaded.value = false
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 500)
    })
  },
})

const dictionary = useDictionary()
const withdrawalTurnoverTypeDic = computed(() => {
  return Object.fromEntries(
    // @ts-ignore
    dictionary.dictionary?.dic_withdrawal_turnover_type?.map((item) => {
      return [item.code, item.title]
    })
  )
})

const records = computed(() => {
  return data.value?.codeRecordsList.map((item, index) => {
    return {
      ...item,
      key: `records-${index}`,
      turnoverType: `${withdrawalTurnoverTypeDic.value[item.turnoverType]}`,
      completed: `$${item.completed}`,
      undone: `$${item.undone}`,
    }
  })
})
const detailList = computed(() => {
  const base = [
    {
      label: "Deductions of winnings",
      text: `$${data.value?.bonus}`,
    },
    {
      label: "Audit Fee",
      text: `$${data.value?.auditFee}`,
    },
    {
      label: "Withdrawable Amount",
      text: `$${data.value?.actualWithdrawal}`,
      color: isActualWithdrawalLessThanMinWithdrawalAmount.value
        ? "red"
        : "white",
    },
  ]
  if (data.value?.isMinActualWithdrawal) {
    base.push({
      label: "Reason",
      text: `Minimum withdrawal amount $${data.value?.minAmount}, you are temporarily unable to withdraw.`,
    })
  }
  return base
})

const unwatch = watch(
  () => data.value,
  (value) => {
    if (value) {
      isLoaded.value = true
    }
  },
  {
    immediate: true,
  }
)

onUnmounted(() => {
  unwatch()
})
</script>
<template>
  <div class="WithdrawReminder flex flex-col space-y-[1em] my-[1em]">
    <p class="font-600 text-[0.8em] leading-[1.5em]">
      Dear member, <br />
      you have not completed the required betting turnover:
    </p>
    <div class="TableWrapper">
      <table class="DetailTable">
        <thead>
          <tr>
            <th>Turnover Type</th>
            <th>Completed</th>
            <th>Undone</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="records?.length">
            <tr v-for="item in records" :key="item.key">
              <td>{{ item.turnoverType }}</td>
              <td>{{ item.completed }}</td>
              <td>{{ item.undone }}</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="3">
              <div class="flex items-center justify-center space-x-[1em]">
                <CommonAsset class="w-[2em] h-[2em]" name="icons-no-data" />
                <span class="font-600 text-gray-600">No Data</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="Detail font-400! text-[0.8em] leading-[1.3em]">
      <ul>
        <li class="space-x-2" v-for="item in detailList" :key="item.label">
          <span class="font-500">{{ item.label }}:</span>
          <span :style="{ color: item.color ?? 'white' }">{{ item.text }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.WithdrawReminder {
  --dialog-table-bg-color: #1e202b;

  .TableWrapper {
    box-sizing: border-box;
    background-color: var(--dialog-table-bg-color);
    border: 2px solid #27333d;
    border-radius: 1em;
  }

  .DetailTable {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      font-size: 1em;
      padding: 0.5em 0.8em;
      border: 2px solid var(--dialog-bg-color);
      text-align: left;
      text-align: center;

      @screen <sm {
        font-size: 0.8em;
      }
    }

    th {
      color: white;
    }
  }
}
</style>

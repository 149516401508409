<script setup lang="tsx">
import { useDialogHook, importTemplates, EnumDialogTemplate } from "#imports"
const emit = defineEmits<{
  (event: "close"): void
}>()
const TemplateMap = await importTemplates()
const { controls, close } = useDialogHook()
const route = useRoute()
const dialogName = computed(
  () =>
    ((route.query.dialogName as string) ??
      EnumDialogTemplate.DEFAULT_404) as EnumDialogTemplate
)
const TemplateName = computed(() => {
  return controls.templateName.value ?? dialogName.value
})

const ContentComponent = computed(() => TemplateMap[TemplateName.value])
const ContentRef = ref<typeof ContentComponent>()

const TitleComponent = computed(() => {
  // @ts-ignore
  return controls.customTitle.value ?? ContentRef.value?.Title
})
const FooterComponent = computed(() => {
  // @ts-ignore
  return ContentRef.value?.Footer
})

const IsVisible = computed(() => {
  return !!ContentComponent && !!route.query.dialogName
})

const onBackdropClick = async () => {
  // @ts-ignore
  await ContentRef.value?.beforeClose()
  close()
}
// @ts-ignore
const isLoaded = computed(() => !!ContentRef.value?.isLoaded)
const onConfirm = computed(() => controls.onConfirm.value ?? (() => {}))
</script>
<template>
  <Teleport v-if="IsVisible" to="body">
    <div
      id="id-common-dialog"
      class="CommonDialog"
      @click.self="onBackdropClick"
      :data-loaded="isLoaded"
    >
      <div class="Mask" />
      <div class="Container">
        <div class="Inner">
          <div class="Header">
            <div v-if="TitleComponent" class="Title">
              <component :is="TitleComponent" />
            </div>
            <CommonAsset
              class="CloseIcon cursor-pointer"
              name="icons-close"
              @click="onBackdropClick"
            />
          </div>
          <div v-if="ContentComponent" class="Content">
            <component
              :is="ContentComponent"
              ref="ContentRef"
              @confirm="onConfirm"
            />
          </div>
          <div v-if="FooterComponent" class="Footer">
            <component :is="FooterComponent" />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<style lang="scss" scoped>
.CommonDialog {
  --boxshadow-px: 5px;
  transition: all 0.3s ease-in-out;
  --dialog-bg-color: #262836;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1em;

  @screen <sm {
  }

  .Mask {
  }

  .Container {
    transition: all 0.5s ease-in-out;
    display: block;
    box-sizing: border-box;
    background-color: var(--dialog-bg-color);
    border-radius: 0.6em;
    max-height: 100%;
    padding: 1em;
    height: auto;

    @screen <sm {
      width: 100%;
    }
  }

  .Inner {
    display: inline-flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    .Header {
      position: relative;
      display: flex;
      align-items: baseline;
      justify-content: center;

      .Title {
        font-size: 1.2em;
        font-weight: bold;
        color: #fff;
        min-width: 300px;
        text-align: center;
      }

      .CloseIcon {
        position: absolute;
        top: 0;
        right: 0;
        width: 1.5em;
        height: 1.5em;
        color: #fff;
      }
    }

    .Content {
      width: 100%;
      max-height: 600px;
      overflow-y: auto;

      @screen <sm {
        width: 100%;
        max-height: 100%;
      }
    }
  }

  &[data-loaded="true"] {
    .Container {
      box-shadow: var(--boxshadow-px) var(--boxshadow-px) var(--boxshadow-px)
        rgba(0, 0, 0, 0.8);
      transform: translate(0, 0);
    }
  }

  &[data-loaded="false"] {
    opacity: 0;

    .Container {
      opacity: 0;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.8);
      transform: translate(5px, 5px);
    }
  }
}
</style>

<template>
  <div
    id="layout-header"
    class="layout-header fixed top-0 z-20 !<md:w-full !<md:left-0"
    :class="{
      'sliderbar-open': isNavBarOpne,
      'sliderbar-close': !isNavBarOpne,
    }"
  >
    <div
      class="consistency-layout-set flex justify-between items-center py-16px <sm:py-8px <sm:px-16px <sm:min-h-46px @sm:py-10px <md:py-12px <md:px-20px <lg:py-14px <lg:px-30px"
    >
      <div class="flex items-center mr-20px <md:mr-0">
        <div
          v-if="isBackwardShow"
          v-show="$route.name !== 'Home'"
          @click="router.back()"
        >
          <CommonAsset
            name="icons-back"
            class="show-logo w-[25px] h-[25px] cursor-pointer"
          />
        </div>
        <div
          v-show="isShowLogo"
          :class="[
            `w-full  flex justify-center`,
            {
              '<md:ml-10px <sm:ml-5px':
                isBackwardShow && $route.name !== 'Home',
              showlogo: isLogin,
            },
          ]"
        >
          <CommonAsset
            class="main-logo show-logo w-150px <sm:flex <sm:w-120px"
            :class="{
              '<sm:hidden': `${coinamount}`.length >= 10,
              '<sm:w-160px': !isLogin,
            }"
            name="images-logo-logo"
            alt="show-logo"
          />
        </div>
      </div>
      <template v-if="!isLogin">
        <section class="flex items-center flex-shrink-0 ml-5px">
          <div
            class="rounded-12px cursor-pointer px-24px py-14px text-14px font-600 mr-12px <sm:px-10px <sm:py-8px <sm:text-12px <sm:rounded-6px <sm:font-600 @sm:px-12px @sm:py-8px @sm:font-100 @md:px-16px @md:py-12px @lg:px-20px @lg:py-13px to-register"
            @click="useDialog().openDialog('PwdSign')"
          >
            {{ t("header.login") }}
          </div>
          <div
            class="rounded-12px cursor-pointer px-24px py-14px text-14px font-600 <sm:px-10px <sm:py-8px <sm:text-12px <sm:rounded-6px <sm:font-600 @sm:px-12px @sm:py-8px @sm:font-100 @md:px-16px @md:py-12px @lg:px-20px @lg:py-13px to-signup"
            @click="useDialog().openDialog('signIn')"
          >
            {{ t("header.signUp") }}
          </div>
          <ElDropdown @command="handleLang">
            <CommonAsset
              name="icons-i18n"
              class="w-30px ml-12px <sm:w-24px"
            />
            <template #dropdown>
              <ElDropdownMenu>
                <ElDropdownItem
                  v-for="(item, itemIndex) in langList"
                  :key="`${itemIndex}-${item.label}`"
                  :command="item"
                  >{{ item.label }}
                </ElDropdownItem>
              </ElDropdownMenu>
            </template>
          </ElDropdown>
        </section>
      </template>
      <template v-if="isLogin">
        <section class="flex items-center user-info <sm:gap-[0.2rem]">
          <span
            class="flex text-18px mr-10px <md:hidden @md:hidden <sm:text-12px <sm:mr-5px <sm:hidden"
          >
            <p class="ml-5px font-600">{{ info?.username ?? "" }}</p>
          </span>
          <div
            class="relative flex items-center font-700 px-17px py-8px text-16px <sm:px-12px <sm:font-500 <sm:py-6px <sm:text-14px <sm:rounded-13px <sm:pr-40px pr-50px rounded-18px s-balance"
          >
            <ElPopover
              placement="bottom"
              :width="300"
              trigger="click"
              @show="showWallets = true"
              @hide="showWallets = false"
            >
              <template #reference>
                <div class="ml-3px flex items-center">
                  <div class="font-semibold flex-1">${{ coinamount }}</div>
                  <div class="w-6 h-6 flex justify-center items-center">
                    <ElIcon
                      :size="12"
                      :class="`transform transition-all ${
                        showWallets ? 'rotate-180' : 'rotate-0'
                      }`"
                    >
                      <ArrowDownBold />
                    </ElIcon>
                  </div>
                </div>
              </template>
              <div class="text-lg">
                <div>
                  <ElInput
                    v-model="keywords"
                    placeholder="搜索"
                    :prefix-icon="Search"
                  />
                </div>
                <div
                  v-for="(item, i) in walletList"
                  :key="i"
                  class="flex items-center gap-3 mt-4"
                >
                  <div class="w-6 h-6">
                    <img :src="item.coinUrl" alt="url" />
                  </div>
                  <div>{{ item.currency }}</div>
                  <div class="flex-1 text-right">
                    {{ item.coin || "0.00" }}
                  </div>
                </div>
                <div class="text-sm">
                  <div class="flex items-center mt-6">
                    <div class="flex-1">隐藏0余额</div>
                    <div class="w-20 text-right">
                      <ElSwitch
                        v-model="persist.showZeroBalance"
                        class="ml-2"
                        style="--el-switch-on-color: #6cb515"
                      />
                    </div>
                  </div>
                  <div class="flex items-center mt-4">
                    <div class="flex-1">显示法币余额</div>
                    <div class="w-20 text-right">
                      <ElSwitch
                        v-model="persist.showFiatBalance"
                        class="ml-2"
                        style="--el-switch-on-color: #6cb515"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ElPopover>
            <div
              class="currency-icon absolute rounded-5px right-0 top-[4px] p-8px <sm:p-5px <sm:text-13px <sm:top-[6px] text-17px cursor-pointer"
              @click="pageTo('My')"
            >
              <CommonAsset
                class="w-17px <sm:w-14px"
                name="icons-wallet"
                alt="icons-wallet"
              />
            </div>
          </div>
          <ElDropdown @command="handleCommand">
            <img
              class="w-30px h-30px rounded-1/2 ml-12px <sm:w-25px <sm:min-w-25px <sm:h-25px <sm:ml-[9px] cursor-pointer"
              src="../../assets/images/avatar/default.png"
              alt="avatar-icon"
            />
            <template #dropdown>
              <ElDropdownMenu>
                <ElDropdownItem
                  v-for="(item, itemIndex) in dropList"
                  :key="`${itemIndex}-${item.label}`"
                  :class="{ 'drop-active': routeName === item.value }"
                  :command="item"
                  >{{ item.label }}
                </ElDropdownItem>
              </ElDropdownMenu>
            </template>
          </ElDropdown>
          <div
            v-show="isCurrentActivityNotEmpty"
            class="flex cursor-pointer items-center justify-center text-28px <sm:w-24px <sm:ml-10px <sm:ml-6px ml-15px items-center"
          >
            <CommonAsset
              name="icons-check-icon"
              class="w-30px <sm:w-24px"
              @click="handleCheckInDialog()"
            />
          </div>
          <div
            class="items-center cursor-pointer relative justify-center <sm:w-24px text-23px ml-6px) text-28px <sm:ml-10px ml-15px items-center"
            @click="pageTo('Information', { category: 1 })"
          >
            <CommonAsset name="icons-notify" class="<sm:w-24px" />
            <span
              v-show="messageCount > 0"
              class="w-7px rounded-5px h-7px absolute right-2px top-2px notify-icon cursor-pointer"
            />
          </div>
          <ElDropdown @command="handleLang">
            <CommonAsset
              name="icons-i18n"
              class="w-30px ml-12px <sm:w-24px"
            />
            <template #dropdown>
              <ElDropdownMenu>
                <ElDropdownItem
                  v-for="(item, itemIndex) in langList"
                  :key="`${itemIndex}-${item.label}`"
                  :command="item"
                  >{{ item.label }}
                </ElDropdownItem>
              </ElDropdownMenu>
            </template>
          </ElDropdown>
        </section>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ElDropdown, ElDropdownMenu, ElDropdownItem } from "element-plus"
import { Search, ArrowDownBold } from "@element-plus/icons-vue"
import { useDialog } from "~/composables/useDialog"
import { useUserStore } from "~/stores/user"
import { useAppStore } from "~/stores/app"
import { useCheckInStore } from "~/stores/checkIn"
import { useMessage } from "~/stores/message"
import { dialogConfirm } from "~/components/custom-dialog/custom"
import { useWallet } from "~~/stores/wallet"
import { usePersistStore } from "~/stores/persist"
import { useDeviceSymbol } from "~/composables/useDeviceSymbol"

const router = useRouter()
const route = useRoute()
const checkInStore = useCheckInStore()
const wallet = useWallet()
const routeName = computed(() => route.name)
const message = useMessage()
const userStore = useUserStore()
const app = useAppStore()
const persist = usePersistStore()
const isLogin = computed(() => !!userStore.isLogin)
const isNavBarOpne = computed(() => userStore.isNavBarOpne)
const isShowLogo = computed(() => userStore.IS_LOGO_SHOW)
const deviceSymbol = useDeviceSymbol()
const { t } = useI18n()
const keywords = ref("")
const showWallets = ref(false)
const isBackwardShow = computed(() => {
  switch (deviceSymbol) {
    case "d":
      return false
    case "m":
      return true
  }
})

const user = useUserStore()
const info = computed(() => user.info)
const isCurrentActivityNotEmpty = computed(() => {
  const activity = checkInStore.currentActivity
  return activity && Object.keys(activity).length > 0
})

type DropType = {
  label: string
  value: any
  count?: number
  params?: {
    category?: number
  }
}
const coinamount = computed(
  () => (wallet.walletList?.reelCoin ?? userStore.info?.coin) || "0.00"
)
const walletList = computed(() => {
  let list = wallet.walletList
    ? wallet.walletList.wallets.filter((item) => [1, 4].includes(item.category))
    : []
  list = [
    {
      currency: wallet.walletList?.mainCurrency as string,
      coin: wallet.walletList?.reelCoin as string,
      frozen: "0",
      category: 1,
      coinUrl: wallet.walletList?.coinUrl as string,
      coinGameType: wallet.walletList?.coinGameType as string,
    },
    ...list,
  ]
  if (keywords.value) {
    list = list.filter((item) => item.currency.includes(keywords.value))
  }
  if (persist.showZeroBalance) {
    list = list.filter((item) => +item.coin !== 0)
  }
  if (!persist.showFiatBalance) {
    list = list.filter((item) => item.coinGameType === "chain.coin")
  }
  return list
})
const messageCount = computed(() => message.noticeCount || 0)
const pageTo = (name: RouteName, params?: DropType["params"]) => {
  navigateTo({ name, params })
}
const dropList = computed(() => {
  return [
    { label: t("header.deposit"), value: "Deposit" },
    { label: t("header.withdraw"), value: "Withdraw" },
    { label: t("header.swap"), value: "Swap" },
    { label: t("header.transfer"), value: "Transfer" },
    { label: t("header.wallet"), value: "Wallet" },
    { label: t("header.accountHistory"), value: "AccountHistory" },
    { label: t("header.bet"), value: "BetRecord" },
    {
      label: t("header.information"),
      value: "Information",
      count: messageCount.value,
      params: {
        category: 1,
      },
    },
    { label: t("header.myAccount"), value: "MyAccount" },
    { label: t("header.activityRecord"), value: "ActivityRecord" },
    // { label: t('header.raffle'), value: "RaffleTicket" },
    { label: t("header.signOut"), value: "signOut" },
  ]
})
const langList = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "中文",
    value: "zh",
  },
]
const handleCommand = (command: DropType) => {
  if (command.value !== "signOut") {
    router.push({ name: command.value, params: command.params ?? {} })
  } else {
    dialogConfirm(t("additional.logout"), t("kyc.reminder01"), {
      showCancelButton: true,
    }).then(() => {
      userStore.LOGOUT(undefined, true)
    })
  }
}
const handleLang = async (command: DropType) => {
  app.SET_LANG(command.value)
  localStorage.setItem("lang", command.value)
  await app.GET_DICTIONARY()
}
const handleCheckInDialog = async () => {
  await checkInStore.GET_CURRENT_ACTIVITY(true)
}

onNuxtReady(async () => {
  await user.GET_USER_PROFILE()
})
</script>

<style lang="scss" scoped>
.layout-header {
  transition: all 0.3s;
  background: var(--v-m);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);

  &.sliderbar-open {
    width: calc(100% - 200px);
    left: 200px;
  }

  &.sliderbar-close {
    width: calc(100% - 70px);
    left: 70px;
  }

  .to-login {
    font-family: "Rubik" !important;
    background: var(--v-header-login-bg);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: var(--v-header-register-color);
  }

  .to-register {
    font-family: "Rubik" !important;
    background: var(--v-header-regiter-bg);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: var(--v-header-register-color);
    // animation: shadowZoom 1s infinite;
  }

  .to-signup {
    font-family: "Rubik" !important;
    background: var(--v-header-signup-bg);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: var(--v-header-register-color);
    // animation: shadowZoom 1s infinite;
  }

  .s-balance {
    background: var(--v-header-balance-bg);
  }

  .user-info {
    color: var(--v-header-color);

    .currency-icon {
      box-shadow: 0px 0px 5.71429px rgba(0, 0, 0, 0.25);
      background: var(--v-header-wallet-bg);
    }

    .notify-icon {
      background: #df342d;
    }
  }

  .old-float {
    position: fixed;
    right: 25px;
    bottom: 230px;
    z-index: 99999;

    &-rounded {
      width: 60px;
      height: 60px;
      border-radius: 100%;
      background-image: linear-gradient(#67a4ff, #115bca);
      padding: 1px;

      &-in {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--v-header-regiter-bg);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: var(--v-header-register-color);
        font-weight: bold;
      }
    }
  }
}

.el-dropdown-menu {
  padding-top: 5px;
  padding-bottom: 5px;
}

@screen md {
  .layout-header {
    background: var(--v-m);
  }
}

/* animation zoom jump in */
@keyframes logo-anime {
  0% {
    transform: scale(1);
  }

  40% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  60% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.main-logo {
  animation: logo-anime 5s infinite alternate ease-in-out;
}
</style>
